const tiles = [
  {
    id: 0,
    teaser: 'Take a tour of the site',
    title: 'Explore climate change projections',
    image: () => require('@/assets/images/gateway/0_tour.png'),
    link: '/tour',
    isVideoTourLink: true,
    icon: () => require('@/assets/images/video_icon.png')
  },
  {
    id: 1,
    title: 'Take a tour of the site',
    teaser: 'Explore climate change projections',
    image: () => require('@/assets/images/gateway/1_growingseason.png'),
    link: '/climate-data'
  },
  {
    id: 2,
    title: 'Is my health at risk from climate change?',
    teaser: 'Learn about the impacts of climate change',
    image: () => require('@/assets/images/gateway/2_impacts.png'),
    link: '/impacts'
  },
  {
    id: 3,
    title: 'What future should my community prepare for?',
    teaser: 'Download your community\'s climate change profile',
    image: () => require('@/assets/images/gateway/3_profiles.png'),
    link: '/communities'
  },
  {
    id: 4,
    title: 'How can I protect my home from wildfire?',
    teaser: 'Get inspired by local examples of climate action',
    image: () => require('@/assets/images/gateway/4_actions.png'),
    link: '/actions'
  },
  {
    id: 5,
    title: 'Why is a 1°C increase such a big deal?',
    teaser: 'Learn how to make sense of climate science',
    image: () => require('@/assets/images/gateway/5_climatesci.png'),
    link: '/about-data'
  }
]
export default tiles;
