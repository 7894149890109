<template>
    <div class='slider-container' data-v-2dedc691="">
       <!-- <a class='text-white no-underline' @click="downloadTabular()" href='public/download-data/TabularData.zip' download><button class='bg-green p-2 m-1'><i class='material-icons material-link pr-1'> link </i>Tabular Data</button></a>
        <a class='text-white no-underline' @click="downloadSpatial()"  href='public/climate-data/BCS_ClimateData_SSP585_1464.csv' download><button class='bg-green p-2 m-1'><i class='material-icons material-link pr-1'> link </i>Spatial Data</button></a>
        -->
        <div class="slider-label text-center align-center pt-2" data-v-2dedc691="">
            <span title="Download .zip file of climate data for personal analysis. The spatial data is suitable for use with GIS applications, while the tabular data is best for analysis with Excel or other software. See readme file contained in zip for more info." 
            v-tippy='{ placement : "top" }'>Download Data</span>
        </div>
        <div class="align-center text-center space-around p-2">
            <a :href="`/download-data/SpatialData.zip`" class="text-white no-underline hover:underline" target="_blank" download>
                <button class='bg-green p-2 m-1'><i class='material-icons material-link pr-1'> link </i>Spatial Data</button>
            </a>            
            <a :href="`/download-data/TabularData.zip`"  class="text-white no-underline hover:underline" target="_blank" download>
                <button class='bg-green p-2 m-1'><i class='material-icons material-link pr-1'> link </i>Tabular Data</button>
            </a>
            <!--
            <a class='text-white no-underline' @click="downloadTabular()"><button class='bg-green p-2 m-1'><i class='material-icons material-link pr-1'> link </i>Tabular Data</button></a>
            <a class='text-white no-underline' @click="downloadSpatial()"><button class='bg-green p-2 m-1'><i class='material-icons material-link pr-1'> link </i>Spatial Data</button></a>
            -->
        </div>
    </div>
</template>

<script>
    // import axios from 'axios';
    
    export default {
        data () {
            return {
                // tabular: "/download-data/TabularData.zip",
                // spatial: "/download-data/SpatialData.zip",
            }
  },

      mounted() {
      },
      methods: {

        // downloadTabular () {
        //     console.log("tabular")
        // },

        // downloadSpatial () {
        //     console.log("spatial")
        // },


        //   downloadFile() {
        //       axios({
        //             url: 'http://localhost:8080/img.png', // File URL Goes Here
        //             method: 'GET',
        //             responseType: 'blob',
        //         }).then((res) => {
        //             console.log(res.data)

        //              var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
        //              var docUrl = document.createElement('x');
        //              docUrl.href = FILE;
        //              docUrl.setAttribute('download', 'file.png');
        //              document.body.appendChild(docUrl);
        //              docUrl.click();
        //         });
        //   }
      }
    }
</script>

<style lang="scss" scoped>

</style>