import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/communities',
      name: 'communities',
      component: () =>
        import(/* webpackChunkName: "actions" */ '@/views/Communities.vue')
    },
    {
      path: '/tour',
      name: 'tour',
      component: () =>
        import(/* webpackChunkName: "actions" */ '@/views/VideoTour.vue')
    },
    {
      path: '/actions',
      name: 'actions',
      component: () =>
        import(/* webpackChunkName: "actions" */ '@/views/Actions.vue')
    },
    {
      path: '/impacts',
      name: 'impacts',
      component: () =>
        import(/* webpackChunkName: "impacts" */ '@/views/Impacts.vue'),
      children: [
        {
          path: 'economy',
          name: 'impacts-economy',
          component: () =>
            import(/* webpackChunkName: "impacts" */ '@/views/impacts/Economy.vue')
        },
        {
          path: 'nature',
          name: 'impacts-nature',
          component: () =>
            import(/* webpackChunkName: "impacts" */ '@/views/impacts/Nature.vue')
        },
        {
          path: 'infrastructure',
          name: 'impacts-homes',
          component: () =>
            import(/* webpackChunkName: "impacts" */ '@/views/impacts/Infrastructure.vue')
        },
        {
          path: 'quality-of-life',
          name: 'impacts-qol',
          component: () =>
            import(/* webpackChunkName: "impacts" */ '@/views/impacts/QualityOfLife.vue')
        }
      ]
    },
    {
      path: '/climate-science',
      name: 'climate-science',
      component: () =>
        import(/* webpackChunkName: "climate-science" */ '@/views/ClimateScience.vue')
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ '@/views/About.vue')
    },
    {
      path: '/about-data',
      name: 'about-data',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ '@/views/AboutData.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ '@/views/Privacy.vue')
    },
    {
      path: '/feedback',
      name: 'feedback',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ '@/views/Feedback.vue')
    }
  ]
})

// This is dumb, but there isn't much else of a choice
// the following keeps track of scroll positioning

const scrollableElementId = 'scrollable'
const scrollPositions = Object.create(null)

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

// only gets fired when pressing a new button.. when NavigationDuplicated isnt happeneing, when $route is set or triggered
router.beforeEach((to, from, next) => {
  const element = document.getElementById(scrollableElementId)
  // console.log("router.beforeEach: ", from.name, to.name)
  if (element !== null) {
    scrollPositions[from.name] = element.scrollTop
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    router.push({ name: to.name, query: from.query })
  } else {
    next()
  }
})

router.afterEach((/* to, from */) => {
  const element = document.getElementById(scrollableElementId)
  if (element !== null) { element.scrollTop = 0 }
})

window.addEventListener('popstate', () => {
  const currentRouteName = router.history.current.name

  const element = document.getElementById(scrollableElementId)
  if (element !== null && currentRouteName in scrollPositions) {
    element.scrollTop = scrollPositions[currentRouteName]
  }
})

export default router
