<template>
  <div class="legend-climate flex flex-col p-2 bg-grey">
    <div class="text-sm mb-1 text-center">{{ this.$store.getters.getClimateTitle }}</div>
    <div class="scale flex h-6 mb-1" 
        :class="reversed ? 'reversed' : ''">
      <span 
        class="h-6" 
        :style="'background-color:' + colourFn(index/100)" 
        :id="'scale-'+index" 
        v-for="index in 100" 
        :key="index"></span>
    </div>
    <div class="text flex justify-between text-xs" v-if="climateDomain">
      <div>{{ climateDomain[0].toFixed(1) || '' }}</div>
      <div><span v-if="reversed">0</span>{{ this.$store.getters.getClimateUnit }}</div>
      <div>{{ climateDomain[1].toFixed(1) || '' }}</div>
    </div>
  </div>
</template>

<script>
import chroma from 'chroma-js'

export default {
  data () { 
    return {
      reversed: false
    }
  },
  computed: {
    colourScale () { return this.$store.state.climateColourTheme },
    climateDomain () { 
      let domain = this.$store.state.climateDomain 
      if (this.reversed) {
        domain = [Math.min(...domain), Math.max(...domain)]
      }
      return domain
    }
  },
  updated () {
    this.colourScale === 'RdYlBu' ? this.reversed = true : this.reversed = false
  },
  methods: {
    colourFn: function (step) {
      return chroma.scale(this.colourScale)(step)
    }
  }
}
</script>

<style scoped lang="scss">
.scale {
  min-width: 260px;

  span { width: 1%; }

  &.reversed {
    transform: scaleX(-1);
  }
}
</style>