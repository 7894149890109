<template>
  <div class="top-0 left-0 z-10 absolute mt-2 cursor-pointer font-condensed" @click="handleLayersClick">
    <div class="px-5 w-24 leading-none text-center py-3 text-white text-xl uppercase" :class="this.$store.state.showLayers ? 'bg-green-medium' : 'bg-green'">
      <img class="icon-medium my-1" src="@/assets/images/icon_map.svg"/>
      <a>Climate Layers</a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleLayersClick () {
      this.$store.commit('LAYERS_TOGGLE')
    }
  }
}
</script>

<style>
</style>
