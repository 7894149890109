<template>
  <div class="sidebar-home">
    <div class="hero md:flex md:justify-between">
      <div class="flex flex-col justify-center">
        <h1 class="text-3xl leading-tight text-center md:text-right md:pr-5">Our climate is changing. </h1>
        <h1 class="text-3xl leading-tight text-center md:text-right md:pr-5 md:pt-4">It's time to learn and act.</h1>
      </div>
      <img class="block md:inline mx-auto md:mx-0" src="@/assets/images/bc_map.svg" />
    </div>
    <div class="subtitle">
      <h2 class="text-green leading-tight font-normal font-script text-3xl text-center md:text-left">Climate information
        resource for the Columbia Basin Boundary Region</h2>
    </div>
    <div class="flex justify-around py-10">
      <div class="text-center">
        <button @click="handleExploreMap" class="gotodata bg-green hover:bg-green-medium text-white font-bold p-4">Take me
          to the
          Data</button>
      </div>
    </div>
    <div class="mobile-more-info" v-if="isMobile">
      <router-link v-for="tile in tiles" :key="tile.id" class="contents" :to="tile.link">
        <Tile class="home__slide" :teaser="tile.teaser" :image="tile.image" :icon="tile.icon" />
      </router-link>
    </div>
    <div>
      <p>The Columbia Basin-Boundary region is already experiencing a climate that’s different from 50 years ago. Even
        with substantial cuts to greenhouse gas emissions, scientists expect our climate to continue changing over the
        coming decades. From ecosystems, communities, and infrastructure, to our way of life—everything will be affected.
      </p>
      <p>Communities across the region and around the world are demonstrating that it’s possible to adapt, to reduce
        greenhouse gas emissions, and continue to thrive. Explore this website to learn more about the changes we’re
        expecting, and what we can do about them.</p>
      <p>The Columbia Basin Climate Source is your one-stop destination for information about climate change, impacts, and
        action in this region.</p>
    </div>
  </div>
</template>
<script>
import Tile from '@/components/tiles/Tile.vue'
import tiles from '@/components/tiles/tiles'
const meta = {
  title: 'Home',
  description: 'Welcome'
}

export default {
  name: 'home',
  metaInfo() {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },
  components: {
    Tile
  },
  computed: {
    isMobile() {
      if (window.innerWidth < 768) {
        return true
      } else {
        return false
      }
    },
  },
  data() {
    return { tiles }
  },
  watch: {
    isMobile: function (nv) {
      console.log("isMobile changed", nv)
    }
  },
  mounted() {
    const mobile = this.isMobileView()
    console.log('mobile', mobile)
    this.$nextTick(() => {
      this.$root.$emit('map/hide')
    })
  },
  methods: {
    handleExploreMap() {
      this.$root.$emit('map/show')
      this.$router.push('about-data')
      this.$root.$emit('layers/show')
    },
    handleTour() {
      if (this.$matomo) this.$matomo.trackEvent('Intro Tour')
      const vm = this
      this.$root.$emit('map/show')
      this.$router.push('about-data')
      this.$store.commit('TOUR_TOGGLE')
      this.$nextTick(() => this.$intro().setOptions({
        overlayOpacity: 0.5
      }).onbeforechange(function (targetElement) {
        if (targetElement.id === 'climate-layer-container') { vm.$store.commit('LAYERS_VISIBILITY', true) }
      }).oncomplete(function () {
        vm.$store.commit('TOUR_TOGGLE')
      }).start())
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-home {
  @apply p-10 pt-6;

  h1 {
    @apply text-green;
    // width: 330px;
  }

  .hero {
    position: relative;
    padding-bottom: 1rem;

    img {
      min-width: 141px;
      max-height: 200px;
    }
  }

  .mobile-more-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 2rem;

    .home__slide {
      width: calc(50% - 0.5rem);
      aspect-ratio: 1 / 1;
      margin-top: 0.5em;
    }
  }

  p+p {
    margin-top: 1rem;
  }

  .gotodata {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;

  }
}
</style>
