
<template>
  <div ref="container" class="tile-grid h-screen">

    <button v-if="!showVideo" class="contents" @click="() => onShowVideo()">
      <Tile class="grid__slide" v-model="videoTile" :teaser="videoTile.teaser" :image="videoTile.image" />
    </button>

    <router-link style="display: contents" v-for="tile in linkTiles" :key="tile.id" :to="tile.link">
      <Tile v-if="!showVideo" class="grid__slide" :teaser="tile.teaser" :image="tile.image" :icon="tile.icon" />
    </router-link>

    <div v-if="showVideo" class="cover-container">
      <button class="cover-close bg-green text-white" @click="onCloseVideo">× Close</button>
      <Video :width="width" :height="height" :openned="showVideo" class="cover-video" />
    </div>

    <div v-if="!showVideo" class="flex flex-wrap self-end flex-end cover-logos justify-between items-center">
      <img class="logo" src="@/assets/images/logo_cbt.png" alt="Columbia Basin Trust Logo">
      <a href="http://www.selkirk.ca">
        <img class="logo" src="@/assets/images/logo_selkirk-innovates.png" alt="Selkirk College Logo">
      </a>
    </div>

  </div>
</template>
<script>
import Tile from '@/components/tiles/Tile.vue'
import tiles from '@/components/tiles/tiles'
import Video from '@/components/Video.vue'
export default {
  components: { Tile, Video },
  props: {
  },
  methods: {
    onShowVideo() {
      this.showVideo = true
    },
    onCloseVideo() {
      this.showVideo = false
    },
    clientContainer() {
      if (this.$refs && this.$refs.container) {
        return {
          height: this.$refs.container.clientHight,
          width: this.$refs.container.clientWidth,
        }
      }
      return { width: null, height: null }
    }
  },
  computed: {
    height() {
      return this.clientContainer().height || 0
    },
    width() {
      return this.clientContainer().width || 0
    },
  },
  data() {
    return {
      linkTiles: tiles.filter(t => !t.isVideoTourLink),
      videoTile: tiles.find(t => t.isVideoTourLink),
      showVideo: false,
    }

  }
}
</script>
<style scoped lang="scss">
:root {}

iframe {
  @apply bg-grey-darkest;
}

.tile-grid {
  $logoHeight: 100px;
  $footerHeight: 30px;
  $manualPadding: 0.5em;
  gap: $manualPadding;
  $contentSize: calc(50vh - #{$footerHeight / 2} - #{$manualPadding} - #{$logoHeight/2});
  $containerHeight: calc(100vh - #{$footerHeight});

  display: grid;
  grid-template-rows: 1fr 1fr #{$logoHeight};
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: end;
  height: $containerHeight;
  margin: auto;
  width: calc(100% - #{2 * $manualPadding});

  .grid__slide {
    height: calc(#{$contentSize} - #{4 * $manualPadding});
    width: $contentSize;
  }

  .logo {
    max-width: 300px;
    max-height: #{$logoHeight};
    width: auto;
    height: auto;
    object-fit: scale-down;
  }

  .cover-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: 1 / 1 / end / span 3;
    height: 100%;
  }

  .cover-close {
    padding: 0.5em 1.5em;
    font-weight: bold;
    border-radius: 10px;
    margin-left: auto;
  }

  .cover-logos {
    height: #{$logoHeight};
    grid-area: 3 / 1 / end / span 3;
  }

  .cover-video {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-items: stretch;
    align-items: center;
    align-content: center;
  }
}

.teaser {
  @apply text-green font-script text-3xl pt-3 pb-3;
}

.embed-responsive-item {
  width: 100%;
}
</style>
