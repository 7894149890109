import Vue from 'vue'
import Vuex from 'vuex'

// import climateLayersSource from '@/climate_layers.json'
import actions from '@/content/actions.json'
import actionLocations from '@/content/action_locations.json'

actions.forEach((action) => {
  action.lonlat = []
  action.location.forEach((location) => {
    const locationSrc = actionLocations.find(
      (item) => item.location === location
    )
    // action.lonlat.push(locationSrc.location)
    action.lonlat.push(locationSrc)
  })
})

Vue.use(Vuex)

const toggle = (key) => (state) => {
  state[key] = !state[key]
}

const climateVariables = {
  accumulated_moisture_annual: {
    name: 'Accumulated Moisture (Annual)',
    description:
      'The sum of total precipitation (mm) over the year minus the computed annual evaporation (mm)',
    domain: {
      min: -376.98898455032855,
      max: 1381.8036340192607
    },
  },
  cooling_degree_days_annual: {
    name: 'Cooling Degree Days (Annual)',
    description:
      'Cooling Degree Days (CDD) is the sum of daily average temperature in excess of 18° C over a season or year. For example, a day with an average temperature of 20° C would contribute 2 CDD to the annual or seasonal total. Generally speaking, a day with average temperature of 18° C or higher would experience daytime high temperatures that would require some cooling for buildings.',
    domain: {
      min: 0,
      max: 1069.2975007572416
    },
  },
  frost_days_annual: {
    name: 'Frost Days (Annual)',
    description:
      'Number of days when the daily minimum temperature is below 0° Celsius',
    domain: {
      min: 25.06896551724138,
      max: 289.26666666666665
    },
  },
  growing_season_length_annual: {
    name: 'Growing Season Length (Annual)',
    description:
      'Annual number of days between the first occurrence of 6 consecutive days when the daily maximum temperature is greater than 5°C and the first occurrence of 6 consecutive days when the daily minimum temperature is less than 5°C.',
    domain: {
      min: 35.13333333333333,
      max: 333.4482758620689
    },
  },
  heating_degree_days_annual: {
    name: 'Heating Degree Days (Annual)',
    description:
      'Heating Degree Days (HDD) is the sum of daily average temperature below 18° C over a season or year. For example, a day with an average temperature of 16° C would contribute 2 HDD to the annual or seasonal total. Generally speaking, a day with average temperature of 18° C or lower would experience nighttime low temperatures that would require some heating for buildings.',
    domain: {
      min: 2237.0258898194484,
      max: 8065.948014909801
    },
  },
  r10mm_annual: {
    name: 'Heavy Rain Days (Annual)',
    description:
      'The number of days within the year when rainfall exceeds 10mm.',
    domain: {
      min: 5.066666666666666,
      max: 69.17241379310344
    },
  },
  hwmtxp_annual: {
    name: 'Heatwave Magnitude (Annual)',
    description:
      'Average temperature across all individual heatwaves April to September',
    domain: {
      min: 15.938750947008783,
      max: 36.51794286138952
    },
  },
  hwntxp_annual: {
    name: 'Heatwave Number (Annual)',
    description: 'The number of individual heatwaves April to September',
    domain: {
      min: 3.2333333333333334,
      max: 9.379310344827584
    },
  },
  ice_days_annual: {
    name: 'Ice Days (Annual)',
    description:
      'Number of days when the maximum temperature is below 0° Celsius',
    domain: {
      min: 5.551724137931035,
      max: 191.9
    },
  },
  lowest_temperature_annual: {
    name: 'Lowest Temperature (Annual)',
    description: 'Coldest daily minimum temperature over a year',
    domain: {
      min: -53.29212735,
      max: -15.352100022
    },
  },
  maximum_1_day_precipitation_annual: {
    name: 'Maximum 1-day Precipitation (Annual)',
    description: 'The maximum daily precipitation over the year (mm).',
    domain: {
      min: 23.16325451,
      max: 198.01636469000005
    },
  },
  maximum_1_day_precipitation_fall: {
    name: 'Maximum 1-day Precipitation (Fall)',
    description: 'The maximum daily precipitation over the fall season (mm)',
    domain: {
      min: 14.374058990000036,
      max: 117.05200457
    },
  },
  maximum_1_day_precipitation_spring: {
    name: 'Maximum 1-day Precipitation (Spring)',
    description: 'The maximum daily precipitation over the spring season (mm).',
    domain: {
      min: 14.374058990000036,
      max: 124.36107515000003
    },
  },
  maximum_1_day_precipitation_summer: {
    name: 'Maximum 1-day Precipitation (Summer)',
    description: 'The maximum daily precipitation over the summer season (mm).',
    domain: {
      min: 13.87051133,
      max: 198.01636469000005
    },
  },
  maximum_1_day_precipitation_winter: {
    name: 'Maximum 1-day Precipitation (Winter)',
    description: 'The maximum daily precipitation over the winter season (mm).',
    domain: {
      min: 14.435095070000044,
      max: 110.88736049
    },
  },
  maximum_temperature_annual: {
    name: 'Maximum Temperature (Annual)',
    description: 'Hottest daily maximum temperature over a year',
    domain: {
      min: 22.481114166,
      max: 49.626910746
    },
  },
  mean_maximum_temperature_annual: {
    name: 'Mean Maximum Temperature (Annual)',
    description: 'The average daily maximum temperature observed over the year',
    domain: {
      min: 0.0062871395601386,
      max: 20.31727967680287
    },
  },
  mean_maximum_temperature_fall: {
    name: 'Mean Maximum Temperature (Fall)',
    description:
      'The average daily maximum temperature observed over the fall season.',
    domain: {
      min: -2.52095720732626,
      max: 20.122979780005306
    },
  },
  mean_maximum_temperature_spring: {
    name: 'Mean Maximum Temperature (Spring)',
    description:
      'The average daily maximum temperature observed over the spring season.',
    domain: {
      min: -1.5403560514086958,
      max: 24.10893589613793
    },
  },
  mean_maximum_temperature_summer: {
    name: 'Mean Maximum Temperature (Summer)',
    description:
      'The average daily maximum temperature observed over the summer season',
    domain: {
      min: 9.579959953991304,
      max: 36.07877805135382
    },
  },
  mean_maximum_temperature_winter: {
    name: 'Mean Maximum Temperature (Winter)',
    description:
      'The average daily maximum temperature observed over the winter season.',
    domain: {
      min: -8.841398498171344,
      max: 7.737303285686663
    },
  },
  mean_minimum_temperature_annual: {
    name: 'Mean Minimum Temperature (Annual)',
    description:
      'The average daily minimum temperature observed over the year.',
    domain: {
      min: -8.300150473752145,
      max: 8.78745899972923
    },
  },
  mean_minimum_temperature_fall: {
    name: 'Mean Minimum Temperature (Fall)',
    description:
      'The average daily minimum temperature observed over the fall season',
    domain: {
      min: -9.735339757193636,
      max: 9.861625927718832
    },
  },
  mean_minimum_temperature_spring: {
    name: 'Mean Minimum Temperature (Spring)',
    description:
      'The average daily minimum temperature observed over the spring season.',
    domain: {
      min: -10.111402871713045,
      max: 10.598929018254871
    },
  },
  mean_minimum_temperature_summer: {
    name: 'Mean Minimum Temperature (Summer)',
    description:
      'The average daily minimum temperature observed over the summer season',
    domain: {
      min: 1.5044708169826089,
      max: 18.526041381710645
    },
  },
  mean_minimum_temperature_winter: {
    name: 'Mean Minimum Temperature (Winter)',
    description:
      'The average daily minimum temperature observed over the winter season.',
    domain: {
      min: -17.70512655485672,
      max: 1.739439069833397
    },
  },
  mean_temperature_annual: {
    name: 'Mean Temperature (Annual)',
    description:
      'The average temperature for the year, using the mean daily average temperature.',
    domain: {
      min: -4.08184967702026,
      max: 14.456934194863482
    },
  },
  mean_temperature_fall: {
    name: 'Mean Temperature (Fall)',
    description:
      'The average of daily average temperature over the fall season',
    domain: {
      min: -6.076277643867375,
      max: 14.620788215458887
    },
  },
  mean_temperature_spring: {
    name: 'Mean Temperature (Spring)',
    description:
      'The average of daily average temperature over the spring season',
    domain: {
      min: -5.825879461560869,
      max: 17.353932457196404
    },
  },
  mean_temperature_summer: {
    name: 'Mean Temperature (Summer)',
    description:
      'The average of daily average temperature over the summer season',
    domain: {
      min: 5.542216491213043,
      max: 26.97349376222114
    },
  },
  mean_temperature_winter: {
    name: 'Mean Temperature (Winter)',
    description:
      'The average of daily average temperature over the winter season',
    domain: {
      min: -13.27326139955539,
      max: 4.738371177760031
    },
  },
  summer_days_annual: {
    name: 'Summer Days (Annual)',
    description:
      'Number of days when the daily maximum temperature exceeds 25° Celsius',
    domain: {
      min: 0,
      max: 148.3448275862069
    },
  },
  tnltm20_annual: {
    name: 'Very Cold Nights (Annual)',
    description:
      'Number of days when minimum temperature is below -20° Celsius',
    domain: {
      min: 0,
      max: 44.03333333333333
    },
  },
  precipitation_total_annual: {
    name: 'Total Precipitation (Annual)',
    description:
      'The total amount of precipitation (in all forms) over a year where daily precipitation > 1.0 mm.',
    domain: {
      min: 397.24000040965706,
      max: 1915.168637420004
    },
  },
  precipitation_total_fall: {
    name: 'Total Precipitation (Fall)',
    description:
      'The total amount of precipitation (in all forms) over the fall season where daily precipitation > 1.0 mm',
    domain: {
      min: 85.2927286496671,
      max: 575.9315017948286
    },
  },
  precipitation_total_spring: {
    name: 'Total Precipitation (Spring)',
    description:
      'The total amount of precipitation (in all forms) over the spring season where daily precipitation > 1.0 mm',
    domain: {
      min: 81.5319717203338,
      max: 461.7622024931044
    },
  },
  precipitation_total_summer: {
    name: 'Total Precipitation (Summer)',
    description:
      'The total amount of precipitation (in all forms) over the summer season where daily precipitation > 1.0 mm',
    domain: {
      min: 57.50124257931067,
      max: 339.1777377100008
    },
  },
  precipitation_total_winter: {
    name: 'Total Precipitation (Winter)',
    description:
      'The total amount of precipitation (in all forms) over the winter season where daily precipitation > 1.0 mm',
    domain: {
      min: 84.62712562366714,
      max: 676.2607392272425
    },
  },
  tropical_nights_annual: {
    name: 'Tropical Nights (Annual)',
    description:
      'Number of days when the minimum temperature exceeds 20° Celsius.',
    domain: {
      min: 0,
      max: 35.03448275862069
    },
  },
  r20mm_annual: {
    name: 'Very Heavy Rain Days (Annual)',
    description: 'The number of days in the year when rainfall exceeds 20mm',
    domain: {
      min: 0.1333333333333333,
      max: 21.75862068965517
    },
  },
}

const climateUnits = {
  accumulated_moisture: 'mm',
  cooling_degree_days: 'days',
  frost_days: 'days',
  growing_season_length: 'days',
  heating_degree_days: 'days',
  hwmtxp: '°C',
  hwntxp: '',
  ice_days: 'days',
  lowest_temperature: '°C',
  maximum_1_day_precipitation: 'mm',
  maximum_temperature: '°C',
  mean_maximum_temperature: '°C',
  mean_minimum_temperature: '°C',
  mean_temperature: '°C',
  precipitation_total: 'mm',
  r10mm: 'days',
  r20mm: 'days',
  summer_days: 'days',
  tnltm20: 'days',
  tropical_nights: 'days',
}

const mapServiceBase =
  'https://egis1.educloud.sgrc.selkirk.ca/arcgis/rest/services'
const mapServiceNonClimate = `${mapServiceBase}/bcs/nonclimatelayers/MapServer`
const mapServiceBioclimate = `${mapServiceBase}/bcs/bioclimate/MapServer`

export default new Vuex.Store({
  state: {
    mapLayers: [
      {
        id: 13,
        name: 'Area of Interest',
        icon: 'aoi',
        color: 'orange',
        ignoreInfo: true,
        ignorePointer: true,
        zIndex: 9,
        source: {
          url: '/layers/bcs_aoi.json',
          type: 'TopoJSON',
          style: {
            stroke: {
              colour: [28, 71, 33, 0.8],
              width: 2,
            },
          },
          legendImage:
            'iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IB2cksfwAAAAlwSFlzAAATrwAAE68BY+aOwwAAANJJREFUSInt1qGuwjAUgOE/pNwqDO7MzOD6IEuT7QV5jS1Z9hDIOQyG46pmyJLmiothEBAr5qa/rDhfWnNquOe8KxQVEiaIjv14BTAAUpVtiFNt2aV0CExIVXY6XBrjvCtCnOqkwmO1864wikrqmyxTVMyL8+622bZrBts4N8DDKz1Bt822Df35uAba+wM2zu+hb5WhDGUoQxn6F5CNc7P3h1VDLRQfIaBeLq0UGUE0MKWe+wyN/XiVquxY7PhU/USOOoSTAdDh0nzjA/k3O5wAfgEAVEIrmNlG2AAAAABJRU5ErkJggg==',
        },
        enabled: true,
      },
      {
        id: 1,
        name: 'Glaciers Over Time',
        description:
        'The extent of glaciers in 1984, 2000, and 2021. Glacier outlines are automatically generated from satellite imagery and are provided by <a href = "https://www.sciencedirect.com/science/article/pii/S0034425721005824" target="_blank">Alex Bevington and Brian Menounos (2022)</a>.',
        icon: 'glacier_extent',
        zIndex: 8,
        color: 'blue',
        timeEnabled: true,
        timeFrames: [
          {
            frame: '1984',
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 40,
          },
          {
            frame: '2000',
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 41,
          },
          {
            frame: '2021',
            default: true,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 42,
          },
        ],
        enabled: false,
      },
      {
        id: 2,
        name: 'Species at Risk',
        description:
          'Species and Ecosystems at Risk as identified by the <a href="https://catalogue.data.gov.bc.ca/dataset/species-and-ecosystems-at-risk-publicly-available-occurrences-cdc" target="_blank">Province of British Columbia</a>. This includes publicly available occurrences only and a level of uncertainty to protect the subject.',
        icon: 'species_at_risk',
        precompose: 'multiply',
        color: 'green',
        source: {
          url: mapServiceNonClimate,
          type: 'MapServer',
          layerId: 2,
        },
        enabled: false,
      },
      {
        id: 3,
        name: 'Current & Modeled Bioclimates',
        zIndex: 2,
        description:
          'Current bioclimates and modeled bioclimates for three future climate scenarios in 2080s. &apos;High Uncertainty&apos; is indicated when Mahalanobis Distance is &gt;1. Produced by <a href="https://basinclimatesource.ca/reports/Ecosystem%20and%20Tree%20Species%20Bioclimate%20Envelope%20Modeling%20for%20the%20West%20Kootenays_GUtzig_2012.pdf">Greg Utzig, P.Ag. of Kootenay Resilience</a>.',
        icon: 'bgc_modeling',
        color: 'purple',
        precompose: 'multiply',
        timeEnabled: true,
        timeFrames: [
          {
            frame: 'Current',
            default: true,
            active: false,
            url: mapServiceBioclimate,
            type: 'MapServer',
            layerId: 0,
          },
          {
            frame: 'Warm-Moist',
            active: false,
            default: false,
            url: mapServiceBioclimate,
            type: 'MapServer',
            layerId: [1,2],
          },
          {
            frame: 'V.Hot-Dry',
            default: false,
            active: false,
            url: mapServiceBioclimate,
            type: 'MapServer',
            layerId: [3,4],
          },
          {
            frame: 'Hot-Wet',
            default: false,
            active: false,
            url: mapServiceBioclimate,
            type: 'MapServer',
            layerId: [5,6],
          },
        ],
        enabled: false,
        source: {
          url: mapServiceBioclimate,
          type: 'MapServer',
          layerId: 1
        }
      },
      {
        id: 4,
        name: 'Clean Energy and Green Building Major Projects',
        description:
        'Private and public sector clean energy and green building construction projects valued at $15M or more for the 3rd quarter of each year. Subsetted from the <a href = "https://www2.gov.bc.ca/gov/content/employment-business/economic-development/industry/bc-major-projects-inventory/recent-reports" target="_blank">BC Major Projects Inventory</a>',
        icon: 'clean_energy_major_projects',
        color: 'yellow',
        precompose: 'multiply',
        timeEnabled: true,
        timeFrames: [
          {
            frame: '2017',
            default: false,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 118
          },
          {
            frame: '2018',
            default: false,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 119
          },
          {
            frame: '2019',
            default: false,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 120
          },
          {
            frame: '2020',
            default: false,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 121
          },
          {
            frame: '2021',
            default: true,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 122
          }
        ],
        enabled: false
        // source: {
        //   url: mapServiceNonClimate,
        //   type: 'MapServer',
        //   layerId: 12
        // },
        // enabled: false
      },
      {
        id: 5,
        name: 'Weather Related Highway Closures',
        description:
          'Road closure incidents from the <a href="https://catalogue.data.gov.bc.ca/dataset/historical-drivebc-events" target="_blank">DriveBC database</a> from 2015-2017 with an identified cause linked to weather.',
        icon: 'weather_road_closures',
        color: 'indigo',
        precompose: 'multiply',
        timeEnabled: true,
        timeFrames: [
          {
            frame: '2015',
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 125
          },
          {
            frame: '2016',
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 124
          },
          {
            frame: '2017',
            default: true,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 123
          }
        ],
        enabled: false
      },
      {
        id: 8,
        name: 'Historic Drought',
        description:
          'Averaged periodic drought condition ranking by basin for 2015-2020 from the <a href="https://governmentofbc.maps.arcgis.com/apps/MapSeries/index.html?appid=838d533d8062411c820eef50b08f7ebc" target="_blank">BC Drought Information Portal</a>',
        icon: 'drought',
        color: 'yellow',
        precompose: 'multiply',
        timeEnabled: true,
        timeFrames: [
          {
            frame: '2015',
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 62
          },
          {
            frame: '2016',
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 63
          },
          {
            frame: '2017',
            default: false,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 64
          },
          {
            frame: '2018',
            default: false,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 65
          },
          {
            frame: '2019',
            default: false,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 74
          },
          {
            frame: '2020',
            default: true,
            active: false,
            url: mapServiceNonClimate,
            type: 'MapServer',
            layerId: 127
          }
        ],
        enabled: false,
      },
      {
        id: 9,
        name: 'Historic Areas Burned',
        description:
          'Mapped extent of wildfires from 1950-2020 from <a href="https://catalogue.data.gov.bc.ca/dataset/fire-perimeters-historical" target="_blank">BC Ministry of Forests, Lands, Natural Resource Operations and Rural Development</a>',
        icon: 'wildfire_history',
        color: 'red',
        precompose: 'multiply',
        source: {
          url: mapServiceNonClimate,
          type: 'MapServer',
          layerId: 81
        },
        enabled: false,
      },
      {
        id: 10,
        name: 'Change in Max 1-Day Rainfall',
        description:
          '2050s vs. Historic<br><br>This shows the change in Max 1-Day Rainfall for the 2050s vs the historic baseline.',
        icon: 'max_1day_rainfall',
        color: 'indigo-dark',
        precompose: 'multiply',
        source: {
          url: mapServiceNonClimate,
          type: 'MapServer',
          layerId: 23,
        },
        enabled: false,
      },
      {
        id: 11,
        name: 'Change in Cooling Degree Days',
        description:
          '2050s vs. Historic<br><br>Cooling Degree Days (CDD) is the sum of daily average temperature in excess of 18° C over a season or year. For example, a day with an average temperature of 20° C would contribute 2 CDD to the annual or seasonal total. Generally speaking, a day with average temperature of 18° C or higher would experience daytime high temperatures that would require some cooling for buildings.',
        icon: 'change_coolingdegdays',
        color: 'blue-light',
        precompose: 'multiply',
        source: {
          url: mapServiceNonClimate,
          type: 'MapServer',
          layerId: 22,
        },
        enabled: false,
      },
      {
        id: 12,
        name: 'Air Monitoring Stations',
        icon: 'air_quality_index',
        description:
          'Air quality monitoring stations within the study area. Click the download link to get the current station data. Based on <a href="https://catalogue.data.gov.bc.ca/dataset/air-quality-monitoring-unverified-hourly-air-quality-and-meteorological-data" target="_blank">station data from DataBC</a>',
        color: 'teal',
        source: {
          url: mapServiceNonClimate,
          type: 'MapServer',
          layerId: 36,
        },
        enabled: false,
      },
    ],
    climateScenario: 'Higher Global Emissions',
    climatePeriod: '2050s',
    climateRange: 'Median',
    climateSeason: 'Annual',
    climateVariable: '',
    climateColourTheme: '',
    climateDomain: '',
    showClimateAnomalies: false,
    csVideo: {
      name: '',
      url: '',
    },
    showMapLayerIcons: false,
    showMapControls: true,
    showMap: true,
    showLayers: false,
    showActionsLayer: false,
    showGridLayer: false,
    showDownloadGridLayer: false,
    actionLocationFilter: '',
    actionTypeFilter: '',
    actionImpactAreaFilter: '',
    tourRunning: false,
  },
  mutations: {
    LAYER_VISIBILITY(state, payload) {
      state.mapLayers.find((x) => x.id === payload.id).enabled = payload.state
    },
    LAYER_ENABLED_BY_VIEW(state, payload) {
      state.mapLayers.find((x) => x.id === payload.id).enabledByView =
        payload.state
    },
    CLIMATE_VIDEO_ENABLE(state, payload) {
      state.csVideo = payload
    },
    MAP_VISIBILITY(state, payload) {
      state.showMap = payload
    },
    MAP_TOGGLE: toggle('showMap'),
    TOUR_TOGGLE: toggle('tourRunning'),
    CONTROL_TOGGLE: toggle('showMapControls'),
    SHOW_MAP_LAYER_ICON(state, payload) {
      state.showMapLayerIcons = payload
    },
    LAYERS_VISIBILITY(state, payload) {
      state.showLayers = payload
    },
    LAYERS_TOGGLE: toggle('showLayers'),
    CLIMATE_RANGE(state, payload) {
      state.climateRange = payload
    },
    CLIMATE_PERIOD(state, payload) {
      state.climatePeriod = payload
    },
    CLIMATE_SCENARIO(state, payload) {
      state.climateScenario = payload
    },
    CLIMATE_VARIABLE(state, payload) {
      state.climateVariable = payload
    },
    CLIMATE_COLOUR_THEME(state, payload) {
      state.climateColourTheme = payload
    },
    CLIMATE_DOMAIN(state, payload) {
      state.climateDomain = payload
    },
    ACTION_LOCATION(state, payload) {
      state.actionLocationFilter = payload
    },
    ACTION_TYPE(state, payload) {
      state.actionTypeFilter = payload
    },
    ACTION_IMPACT(state, payload) {
      state.actionImpactAreaFilter = payload
    },
    ACTION_LAYER_VISIBILITY(state, payload) {
      state.showActionsLayer = payload
    },
    GRID_LAYER_VISIBILITY(state, payload) {
      state.showGridLayer = payload
    },
    DOWNLOAD_LAYER_VISIBILITY(state, payload) {
      state.showDownloadGridLayer = payload
    },
    LAYER_TIME_ACTIVE(state, payload) {
      // payload = { id, frame, status }
      state.mapLayers
        .find((x) => x.id === payload.id)
        .timeFrames.find(
          (timeFrames) => timeFrames.frame === payload.frame
        ).active = payload.status
    },
  },
  getters: {
    climatePeriod: (state) => {
      const periods = {
        '1960s': '1951_1980',
        '2020s': '2011_2040', // 2011-2040
        '2030s': '2021_2050',
        '2040s': '2031_2060',
        '2050s': '2041_2070',
        '2060s': '2051_2080',
        '2070s': '2061_2090',
        '2080s': '2071_2099',
      }
      return periods[state.climatePeriod]
    },

  // New layers, naming variables --update
    activeClimateLayer: (state, getters) => {
      if (state.climateVariable) {
        const ssp =
          // state.climateScenario === 'Higher Global Emissions' ? 'rcp85' : 'rcp45'
          state.climateScenario === 'Higher Global Emissions' ? 'ssp585' : 'ssp245'


        const ranges = {
          Median: 'median',
          Low: 'low',
          High: 'high',
        }

        const modelRange = ranges[state.climateRange]

    //old data path
        // const layer = `${rcp}_${state.climateVariable}_${modelRange}`
        const layer = `${ssp}_${state.climateVariable}_${modelRange}`

        // // const layerinfo = climateLayersSource.find(x => x.name === layer)

        // // const layerSource = {
        // //   url: `${mapServiceBase}/${layerinfo.server}/MapServer`,
        // //   layerId: layerinfo.id
        // // }

        // const layerSource = `/climate-values/${layer}.csv`
        const layerSource = `/climate-values/${layer}.csv`

        return {
          layer: layer,
          source: { type: 'csv', url: layerSource },
          ssp: ssp,
          modelRange: modelRange,
          variable: state.climateVariable,
          timePeriod: getters.climatePeriod,
        }
      } else {
        return undefined
      }
    },

    getMapLayerById: (state) => (id) => {
      return state.mapLayers.find((layer) => layer.id === id)
    },

    activeLayers: (state) => {
      return state.mapLayers.filter((layer) => layer.enabled === true)
    },

    activeTimeLayers: (state) => {
      return state.mapLayers.filter(
        (layer) => layer.timeEnabled === true && layer.enabled === true
      )
    },

    activeTimeLayerFrame: (state) => (id) => {
      return state.mapLayers
        .find((x) => x.id === id)
        .timeFrames.find((timeFrames) => timeFrames.active === true).frame
    },

    actions: () => {
      return actions
    },

    actionsFiltered: (state) => {
      let filtered = actions.sort((a, b) => a.title.localeCompare(b.title))

      if (state.actionLocationFilter) {
        filtered = filtered.filter((action) =>
          action.location.includes(state.actionLocationFilter)
        )
      }

      if (state.actionTypeFilter) {
        filtered = filtered.filter((action) =>
          action.type.includes(state.actionTypeFilter)
        )
      }

      if (state.actionImpactAreaFilter) {
        filtered = filtered.filter((action) =>
          action.impactArea.includes(state.actionImpactAreaFilter)
        )
      }

      return filtered
    },

    getClimateTitle: (state) => {
      if (state.climateVariable) {
        return climateVariables[state.climateVariable].name
      }
    },

    getClimateDescription: () => (abbr) => {
      return climateVariables[abbr].description
    },

    getClimateDomain: () => (variable) => {
      return climateVariables[variable].domain
    },

    getClimateUnit: (state) => {
      if (state.climateVariable) {
        const factor = state.climateVariable.split('_')
        factor.pop()
        return typeof climateUnits[factor.join('_')] !== 'undefined'
          ? climateUnits[factor.join('_')]
          : ''
      }
    },
  },
  actions: {
    resetActions(context) {
      context.commit('ACTION_IMPACT', '')
      context.commit('ACTION_TYPE', '')
      context.commit('ACTION_LOCATION', '')
    },
  },
})
