<template>

  <li class="legend-item py-1 text-sm">
    <div v-if="isComplex !== true" class="legend_simple-item">
      <span class="patch"><img width="15" height="15" :src="`data:image/png;base64,${patch}`"/></span>
      <span class="label pl-1">{{ layerName }}</span>
    </div>
    <div v-else class="legend_complex-item">
      <div class="label pb-1">{{ layerName }}</div>
      <ul class="legend_complex-patches">
        <li v-for="patch in legendObject" v-bind:key="patch.label">
          <img width="15" height="15" :src="`data:image/png;base64,${patch.imageData}`"/>
          <span class="label pl-1">{{ patch.label }}</span>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  props: ['legendObject', 'layerName'],
  computed: {
    isComplex () { return this.legendObject.length > 1 },
    patch () { return this.legendObject[0].imageData }
  }
}
</script>

<style lang="scss">
.legend_simple-item {
  display: flex;
}

.legend_complex-patches {
  list-style: none;
  @apply pl-2 pt-0 pb-0 text-xs;

  li {
    @apply flex;
    height: 15px;

    span {
      @apply flex-grow;
    }
  }
}
</style>
