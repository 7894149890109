<template>
  <div :title="$store.getters.getClimateDescription(name)" v-tippy :class="`item ${active ? `active` : ''}`"
    @click="handleClick">
      <div class="item-text"><slot/></div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    type: String
    // active: Boolean
  },
  computed: {
    color () {
      switch (this.type) {
        case 'wet': return 'teal'
        case 'expri': return 'blue'
        case 'avg': return 'orange'
        case 'grow': return 'green-alt'
        case 'hot': return 'red'
        default: return 'grey'
      }
    },
    active () { return this.$store.state.climateVariable === this.name }
  },
  methods: {
    handleClick () {
      if (this.$store.state.climateVariable === this.name) {
        this.$store.commit('CLIMATE_VARIABLE', '')
      } else {
        this.$store.commit('CLIMATE_VARIABLE', this.name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-basis: calc(33.3333% - 0.5rem);
  // height: 5rem;

  // margin: 0.25rem;
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  // padding: 0.25rem;
  text-align: center;

  cursor: pointer;
}

.bg-red .item.active {
  @apply bg-red-dark;
}
.bg-orange .item.active {
  @apply bg-orange-dark;
}
.bg-green-alt .item.active {
  @apply bg-green-alt-dark;
}
.bg-teal .item.active {
  @apply bg-teal-dark;
}
.bg-blue .item.active {
  @apply bg-blue-dark;
}

.item-text {
  font-size: 0.75rem;
}
</style>
