<template>
  <div>
    <ul class="">
      <slot/>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>
