import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.scss'
import VueIntro from 'vue-introjs'
import VueTippy from 'vue-tippy'
import Meta from 'vue-meta'
import Notifications from 'vue-notification'
import VShowSlide from 'v-show-slide'
import VueObserveVisibility from 'vue-observe-visibility'
import VueMatomo from 'vue-matomo'
import Embed from 'v-video-embed'
import vueVimeoPlayer from 'vue-vimeo-player'

Vue.use(vueVimeoPlayer)


import 'intro.js/introjs.css'

Vue.config.productionTip = false
Vue.use(VShowSlide)
Vue.use(VueObserveVisibility)
Vue.use(VueIntro, { waitTimeout: 600 })
Vue.use(VueTippy, {
  placement: 'bottom',
  arrow: true,
  duration: [350, 200],
  theme: 'bcs'
})
Vue.use(Meta)
Vue.use(Notifications)
Vue.use(VueMatomo, {
  host: '//basinclimatesource.ca/analytics',
  siteId: 1,
  router: router,
  enableLinkTracking: true
})

Vue.mixin({
  methods: {
    isMobileView() {
      if (window.innerWidth < 768) {
        return true
      } else {
        return false
      }
    }
  }
})
// global register
Vue.use(Embed);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
