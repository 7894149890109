<template>
  <div>
    <div class="legend bg-grey p-2" v-if="!isFetching && isDisplayed">
      <ul>
        <transition-group name="slide-fade">
        <LegendItem
          v-for="layer in activeLayers"
          :key="layer.id"
          :legendObject="getLegendObject(layer)"
          :layerName="layer.name" />
        
        <!-- This is a bit hack-y; but it does what it needs to -->
        <LegendItem
          layerName="High Uncertainty Indicator"
          v-if="activeLayers.find(x => x.id === 3) !== undefined"
          key="999999"
          :legendObject='
            [
              {
                "label": "MD > 1.1",
                "url": "8463507cd953d2f701e7f911a9ecb756",
                "imageData": "iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IB2cksfwAAAAlwSFlzAAATrwAAE68BY+aOwwAAAE1JREFUSInt0zEOACAIA0BI+P+XdXJDRNM0Du3YgRuAMFJCkCBBdGgknaOhDFn9EetCO6SNfbsjQdeQW30QsKurMPgftYcioOcIEsSHJhDNB2oObKK7AAAAAElFTkSuQmCC",
                "contentType": "image/png",
                "height": 26,
                "width": 26
              }
            ]
          ' />

        </transition-group>
        <!-- <LegendItem
          v-if="activeClimateLayer && isFetchingClimate === false"
          :key="$store.getters.getClimateTitle"
          :legendObject="getLegendObject(activeClimateLayer)"
          :layerName="$store.getters.getClimateTitle" /> -->
      </ul>
    </div>
  </div>
</template>

<script>
import LegendItem from '@/components/LegendItem.vue'

const legendData = {}

export default {
  components: { LegendItem },
  data () {
    return {
      isFetching: true,
      isFetchingClimate: true,
      isDisplayed: true,
      patchSize: [15, 15],
      patchDpi: 128
    }
  },
  computed: {
    activeLayers () { return this.$store.getters.activeLayers },
    activeClimateLayer () { return this.$store.getters.activeClimateLayer }
  },
  created: function () {
    const mapServers = new Set()
    this.$store.state.mapLayers.forEach(layer => {
        if (layer.timeFrames) {
          layer.timeFrames.forEach(source => { mapServers.add(source.url) })
        } else if (layer.source.type === 'MapServer') {
          mapServers.add(layer.source.url)
        }
    })
    
    this.getLegendData(mapServers).then(() => { this.isFetching = false })
  },
  methods: {
    getLegendData (mapServers) {
      const mapServerUrls = Array.from(mapServers).map(server => {
        const query = {
          f: 'json',
          size: this.patchSize,
          dpi: this.patchDpi
        }
        const url = new URL(`${server}/legend`)
        url.search = new URLSearchParams(query)
        return url
      })

      return Promise.all(mapServerUrls.map(server => {
        return fetch(server).then(response => response.json())
          .then(response => {
            legendData[server.protocol + '//' + server.hostname + server.pathname.split('/').slice(0, -1).join('/')] = response.layers
          })
      }))
    },

    getLegendObject (layer) {
      if (layer.source.type === 'TopoJSON' && layer.source.legendImage) {
        return [{ imageData: layer.source.legendImage }]
      }
      // if a MapServer -> climate layers don't have the correct type set
      else if (layer.source.type !== 'csv' && legendData[layer.source.url]) {
        const layerId = Array.isArray(layer.source.layerId) ? layer.source.layerId[1] : layer.source.layerId
        return legendData[layer.source.url].find(x => x.layerId === layerId).legend
      } else {
        return [{}]
      }
    }
  }
}
</script>

<style lang="scss">
.legend {
  // width: max-content;
  max-height: 500px;
  transition: all 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;

  ul {
    list-style: none;
    padding-left: 0;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
