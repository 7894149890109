<template>
  <div class="gateway text-center h-full">

    <LandingPageCover v-if="getRoute() === '/'" />

    <div class="w-full h-full" v-if="getRoute() === '/climate-science'">
      <div class="w-full h-full">
        <iframe class="w-full h-full" v-if="csVideo.url" :src="`${csVideo.url}?app_id=122963`" frameborder="0"
          title="CBT Vid 10 myth 3" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script lang="jsx">
import tiles from '@/components/tiles/tiles'
import LandingPageCover from './LandingPageCover.vue'
export default {
  components: { LandingPageCover },
  props: {
  },
  data() {
    return {
      linkTiles: tiles.filter(t => !t.isVideoTourLink),
      videoTile: tiles.find(t => t.isVideoTourLink),
      showVideo: false,
      tinySliderOptions: {
        mouseDrag: true,
        loop: true,
        items: 1,
        gutter: 20,
        swipeAngle: 45,
        controls: false,
        autoplay: true,
        autoplayTimeout: 10000,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        autoplayResetOnVisibility: true
      },
    }
  },
  computed: {
    showTiles() { return !this.showVideo && this.getRoute() === '/' },
    showClimateScience() { return this.getRoute() === '/climate-science' },
    csVideo() { return this.$store.state.csVideo }
  },
  methods: {
    defaultPage() {
      return;
    },
    onShowVideo() {
      console.log('show video')
      this.showVideo = true
    },
    toggleMap(state) {
      if (state !== undefined) {
        this.$store.commit('MAP_VISIBILITY', state)
      } else {
        this.$store.commit('MAP_TOGGLE')
        // this.showMap ? this.showMap = false : this.showMap = true
      }
    },
    handleExploreMap() {
      this.$root.$emit('map/show')
      this.$router.push('communities')
      this.$root.$emit('layers/show')
    },
    getRoute() {
      return this.$route.path
    }
  }
}
</script>

<style scoped lang="scss">
iframe {
  @apply bg-grey-darkest;
}

.teaser {
  @apply text-green font-script text-3xl pt-3 pb-3;
}

.gateway {
  height: inherit;
}

.gateway button.go,
.gateway a.go {
  display: inline-block;
  @apply ml-4 font-sans bg-green text-white font-bold py-2 px-4;
}

.gateway button.go:hover {
  @apply bg-green-dark;
}
</style>
