<template>
  <div class="slider-container">
    <div class="slider-label"><slot></slot></div>
    <vue-slider ref="slider" v-bind="slider" v-model="value"></vue-slider>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component'

export default {
  components: { vueSlider },
  props: {
    options: Array,
    defaultOption: String,
    storeMutation: String
  },
  data () {
    return {
      // value: this.defaultOption,
      slider: {
        width: '100%',
        height: 10,
        dotSize: 23,
        tooltip: false,
        disabled: false,
        piecewise: false,
        piecewiseLabel: true,
        style: {
          marginLeft: '0%'
        },
        data: this.options,
        piecewiseStyle: {
          backgroundColor: '#1C4721',
          visibility: 'visible',
          width: '22px',
          height: '22px'
          // marginTop: '-1px'
        },
        sliderStyle: [
          {
            backgroundColor: '#fff'
          },
          {
            backgroundColor: '#34B44D'
          }
        ],
        bgStyle: {
          backgroundColor: '#F2F3F2',
          border: 'solid 1px #919291',
          cursor: 'pointer'
        },
        piecewiseActiveStyle: { backgroundColor: '#1C4721' },
        labelActiveStyle: { color: '#ffffff' },
        labelStyle: {
          color: '#ffffff',
          'white-space': 'inherit',
          'text-align': 'center'
        },
        processStyle: { backgroundColor: '#F2F3F2' }
      }
    }
  },
  computed: {
    isMobile () { if (window.innerWidth < 768) { return true } else { return false } },
    value: {
      get: function () { return this.defaultOption },
      set: function (newValue) { this.$store.commit(this.storeMutation, newValue) }
    }
  },
  watch: {
    // value: function (newValue, oldValue) {
    //   this.$store.commit(this.storeMutation, newValue)
    // }
  }
}
</script>

<style scoped lang="scss">
.slider-label {
  text-align: center;
  padding-top: 1rem;
}
.vue-slider-piecewise-label {
  white-space: inherit !important;
}
</style>
