<template>
  <div id="map-container" class="">
    <slot></slot>
    <div ref="popup" id="popup" class="ol-popup">
      <div id="popup-closer" @click="closePopup(popup)" class="ol-popup-closer cursor-pointer"></div>
      <div id="popup-content" ref="popupContent">
          <div class="popup-title font-bold bg-green p-3 text-white">
            <a :href="currentInfo.link" class="text-white no-underline hover:underline" v-if="currentInfo.link" target="_blank">
              <i class="material-icons material-link"> link </i>
              {{ currentInfo.layerName }}
            </a>
            <span v-else>{{
              currentInfo.layerName ?
              currentInfo.layerName.startsWith('rcp') ? $store.getters.getClimateTitle : currentInfo.layerName
              : ''
              }}</span>
          </div>
          <div class="popup-content p-3 text-sm" v-if="currentInfo.description" v-html="currentInfo.description" ></div>
          <div class="popup-content p-3 text-sm popup-attributes" v-if="Object.keys(filteredAttributes).length > 0" >
            <table>
              <tbody>
                <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                <tr v-for="(value, key) in filteredAttributes" :key="key" v-if="value != ' '">
                  <td class="attribute" v-if="Object.keys(filteredAttributes).length > 1">{{key}}</td>
                  <!-- If climate layer, length === 1,  -->
                  <td v-if="currentInfo.layerName.startsWith('rcp')" v-html="valueFormatter(value)"></td>
                  <td v-else v-html="value"></td>
                </tr>
              </tbody>
            </table>
          </div>

      </div>
      <div v-if="currentInfos.length > 1" id="popup-pager" class="popup-pager flex items-center">
        <span class="popup-pager-left cursor-pointer" @click="infoPaginate('backword')"><i class="material-icons"> arrow_left </i></span>
        <span class="text-xs"> {{ this.infoPage + 1 }} / {{ this.currentInfos.length }} </span>
        <span class="popup-pager-right cursor-pointer" @click="infoPaginate('forward')"><i class="material-icons"> arrow_right </i></span>
      </div>
    </div> <!-- end popup -->
    <div id="map" ref="mapframe" class="map-frame">

    </div>
  </div>
</template>

<script>
import 'ol/ol.css'
import { Map, View } from 'ol'

import { Tile, Image as ImageLayer, Vector as VectorLayer } from 'ol/layer'
import { fromLonLat } from 'ol/proj'
import { BingMaps, ImageArcGISRest, Vector as VectorSource } from 'ol/source'
import Overlay from 'ol/Overlay'

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { Icon, Style, Stroke, Fill } from 'ol/style'

import WKT from 'ol/format/WKT'
import TopoJSON from 'ol/format/TopoJSON'

import { omit } from 'ramda'

import { csv } from 'd3-fetch'
import chroma from 'chroma-js'

let communityLayer, actionsLayer, gridLayer, gridDownloadLayer

const communityMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: require('@/assets/images/maplayers/community.png')
  })
})

const weatherStationMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: require('@/assets/images/maplayers/weather_station.png')
  })
})

const parkMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: require('@/assets/images/maplayers/forest.png')
  })
})

const qolMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: require('@/assets/images/maplayers/mapicon_qol.png')
  })
})

const economyMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: require('@/assets/images/maplayers/mapicon_economy.png')
  })
})

const infrastructureMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: require('@/assets/images/maplayers/mapicon_infrastructure.png')
  })
})

const natureMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: require('@/assets/images/maplayers/mapicon_nature.png')
  })
})

const gridStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(80, 80, 80, 0.4)',
    width: 1
  })
})

const gridDownloadStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(52,180,77, 0.8)',
    width: 1
  }),
  fill: new Fill({
    color: 'rgba(52,180,77, 0.1)'
  })
})

const colourIndex = {}

export default {
  data () {
    return {
      mapEl: undefined,
      searchResultsLayer: undefined,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      activeClimateLayerOl: {},
      currentInfos: [],
      infoPage: 0,
      popup: {},
      showCommunitiesLayer: false,
      currentClimateData: []
    }
  },
  computed: {
    activeClimateLayer () { return this.$store.getters.activeClimateLayer },
    activeTimeLayers () { return this.$store.getters.activeTimeLayers },
    actionsFiltered () { return this.$store.getters.actionsFiltered },
    showActionsLayer () { return this.$store.state.showActionsLayer },
    showGridLayer () { return this.$store.state.showGridLayer },
    showDownloadGridLayer () { return this.$store.state.showDownloadGridLayer },
    activeLayers () { return this.mapEl.getLayers().getArray().filter(layer => { return layer.get('type') !== 'base' }) },
    currentInfo () { return this.currentInfos.length > 0 ? this.currentInfos[this.infoPage] : {} },
    filteredAttributes () { return omit(['objectid_1', 'SHAPE', 'st_area_sh', 'st_length_', 'st_area(shape)', 'st_length(shape)', 'Stretched value', 'Shape', 'Shape_Area', 'Shape_Length', 'OBJECTID', 'OBJECTID_1', 'objectid', 'shape', 'shape_Length', 'shape_Area'], this.currentInfo.attributes) }
  },
  mounted () {
    const mapView = new View({})

    const basemap = new Tile({
      preload: Infinity,
      visible: true,
      zIndex: -100, // make this always the lowest layer, could be a problem with multiple basemaps?
      name: 'bing',
      type: 'base',
      ignoreInfo: true,
      ignorePointer: true,
      source: new BingMaps({
        key: 'AtMk7Y4vP7pVec-QBGD3ZCGNJQkA7kzsKcXiQr48UIATacRee7eKjRXumSSX-t3P',
        imagerySet: 'Road'
      })
    })

    this.mapEl = new Map({
      target: 'map',
      loadTilesWhileInteracting: true,
      loadTilesWhileAnimating: true,
      layers: [basemap],
      view: mapView
    })

    mapView.fit([-13447549.400204774, 6274861.3816, -12696457.7845, 6960959.310699999], {
      constrainResolution: false,
      padding: [15, 50, 10, 10]
    })

    // setup resize
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight
        this.windowWidth = window.innerWidth
        this.mapEl.updateSize()
      })
    })

    this.popup = new Overlay({
      element: this.$refs.popup,
      positioning: 'bottom-center',
      stopEvent: true,
      offset: [2, -25]
    })
    this.mapEl.addOverlay(this.popup)

    this.mapEl.on('pointermove', event => {
      if (event.dragging) {
        return
      }
      const pixel = this.mapEl.getEventPixel(event.originalEvent)
      const hit = this.mapEl.forEachLayerAtPixel(pixel, () => { return true },
        {
          layerFilter: layer => {
            return layer.get('ignorePointer') !== true //! ['bing', 'climate'].includes(layer.get('name'))
          }
        }
      )
      this.mapEl.getTargetElement().style.cursor = hit ? 'pointer' : ''
    })

    this.mapEl.on('click', this.infoClickHandler)

    this.$root.$on('map/layer-enable', layer => this.addLayer(layer))
    this.$root.$on('map/layer-disable', layer => this.removeLayer(layer))
    this.$root.$on('map/add-communities', communities => this.addCommunityLayer(communities))
    this.$root.$on('map/remove-communities', () => this.removeCommunityLayer())
    this.$root.$on('map/zoom-to', options => this.zoomTo(options.lon, options.lat, options.zoom))
    this.$root.$on('map/reset', () => this.mapReset())

    this.$store.getters.activeLayers.forEach(layer => { this.$root.$emit('map/layer-enable', layer.id) })
  },
  methods: {
    searchResult (wkt) {
      this.searchResultsLayer.getSource().clear()

      const format = new WKT()

      const feature = format.readFeature(wkt, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      })
      this.searchResultsLayer.getSource().addFeature(feature)

      // Pan and zoom map
      this.mapEl.getView().animate({
        center: feature.getGeometry().getCoordinates(),
        zoom: this.mapEl.getView().getZoom() >= 15 ? this.mapEl.getView().getZoom() : 15,
        duration: 500
      })
    },

    addLayer (id) {
      this.$matomo && this.$matomo.trackEvent('Map', 'Add Layer', this.$store.getters.getMapLayerById(id).name)
      this.closePopup(this.popup) // make sure popups are closed
      // console.log('Add Layer:', id)
      const layer = this.$store.getters.getMapLayerById(id)
      let olObject = {}

      if (layer.timeEnabled) {
        // create source with default frame, or frame[0] when first enabled
        layer.source =
          layer.timeFrames.find(frame => frame.active === true) ||
          layer.timeFrames.find(frame => frame.default === true) ||
          layer.timeFrames[0]
        this.$store.commit('LAYER_TIME_ACTIVE', { id: id, frame: layer.source.frame, status: true })
      }

      if (layer.source.type === 'MapServer') { olObject = this.createArcGISMapServerLayer(layer, id) }
      else if (layer.source.type === 'TopoJSON') { olObject = this.createTopoJSONLayer(layer, id) }

      if (layer.ignoreInfo) olObject.set('ignoreInfo', true)
      if (layer.ignorePointer) olObject.set('ignorePointer', true)

      this.mapEl.addLayer(olObject)
      // console.log('Active map layers:', this.activeLayers)
    },

    removeLayer (id) {
      this.closePopup(this.popup) // make sure popups are closed
      const layers = this.activeLayers.filter(layer => layer.get('id') === id)
      // console.log(`Removing Layers by id ${id}`, layers)
      layers.forEach(layer => {
        this.mapEl.removeLayer(layer)
      })
      // console.log('Active map layers:', this.activeLayers)
    },

    createArcGISMapServerLayer (layer, id) {
      const newLayer = new ImageLayer({
        className: layer.precompose ? `bm-${layer.precompose} lyr-${layer.id}` : `lyr-${layer.id}`,
        id: id,
        name: layer.name,
        type: layer.source.type || 'RASTER',
        source: new ImageArcGISRest({
          ratio: 1,
          url: layer.source.url,
          crossOrigin: 'anonymous',
          params: { layers: `show:${layer.source.layerId}` }
        })
      })
      if (layer.zIndex) { newLayer.setZIndex(layer.zIndex) } else { newLayer.setZIndex(10) }

      return newLayer
    },

    createTopoJSONLayer (layer, id) {
      const newLayer = new VectorLayer({
        className: layer.precompose ? `bm-${layer.precompose} lyr-${layer.id}` : `lyr-${layer.name}`,
        id: id,
        name: layer.name,
        source: new VectorSource({ url: layer.source.url, format: new TopoJSON() }),
        style: new Style({
          stroke: layer.source.style.stroke ? new Stroke({
            color: layer.source.style.stroke.colour,
            width: layer.source.style.stroke.width
          }) : undefined,
          fill: layer.source.style.fill ? new Fill({
            color: layer.source.style.fill.colour
          }) : undefined
        })
      })
      if (layer.zIndex) { newLayer.setZIndex(layer.zIndex) } else { newLayer.setZIndex(10) }

      return newLayer
    },

    communitiesPopup (evt) {
      console.log('Triggered by:', evt)
      const features = []
      this.mapEl.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer.get('name') === 'communities') { features.push(feature) }
      })

      if (features.length > 0) {
        features.forEach(feature => {
          const coordinates = feature.getGeometry().getCoordinates()
          this.popup.setPosition(coordinates)
          this.currentInfos.push({
            layerName: `Community: ${feature.get('name')}`,
            link: `/profiles/${feature.get('pdf')}`
          })
        })
        return true
      }
    },

    addCommunityLayer (communities) {
      const features = []
      communities.forEach(marker => {
        const markerFeature = new Feature({
          geometry: new Point(fromLonLat(marker.location)),
          name: marker.name,
          pdf: marker.pdf
        })
        if (marker.type === 'weather') {
          markerFeature.setStyle(weatherStationMarkerStyle)
        } else if (marker.type === 'park') {
          markerFeature.setStyle(parkMarkerStyle)
        } else {
          markerFeature.setStyle(communityMarkerStyle)
        }
        features.push(markerFeature)
      })

      communityLayer = new VectorLayer({
        name: 'communities',
        renderMode: 'vector',
        ignoreInfo: true,
        updateWhileInteracting: true,
        source: new VectorSource({
          features: features
        })
      })

      communityLayer.setZIndex(10)
      communityLayer.on('precompose', evt => {
        evt.context.globalCompositeOperation = 'source-over'
      })

      this.mapEl.addLayer(communityLayer)
      this.showCommunitiesLayer = true
    },

    removeCommunityLayer () {
      this.mapEl.removeLayer(communityLayer)
      if (this.popup.getPosition() !== undefined) { this.closePopup(this.popup) }
      this.showCommunitiesLayer = false
    },

    addGridLayer () {
      console.log('Add Grid Layer')

      gridLayer = new VectorLayer({
        name: 'grid',
        ignoreInfo: true,
        ignorePointer: true,
        source: new VectorSource({ url: '/layers/bcs_anusplingrid.json', format: new TopoJSON() }),
        style: gridStyle
      })

      gridLayer.setZIndex(50)
      gridLayer.on('precompose', evt => {
        evt.context.globalCompositeOperation = 'source-over'
      })

      this.mapEl.addLayer(gridLayer)
    },

    removeGridLayer () {
      this.mapEl.removeLayer(gridLayer)
    },

    addClimateDataLayer (climateLayer, styleOnly) {
      csv(climateLayer.source.url).then(data => { 
        this.currentClimateData = data
        console.log('Adding climate data layer', climateLayer)

        let domain = [this.$store.getters.getClimateDomain(climateLayer.variable).min, this.$store.getters.getClimateDomain(climateLayer.variable).max]
        
        // Set the Colour Theme
        let colourTheme = 'YlGnBu' // default to blue for precipitation
        if (!climateLayer.variable.includes('lowest_temperature_annual') && // don't change lowest_temperature_annual
            climateLayer.variable.includes('temperature') ||
            climateLayer.variable.includes('hwmtxp') ||
            climateLayer.variable.includes('hwntxp') ||
            climateLayer.variable.includes('tropical') ||
            climateLayer.variable.includes('summer_days')) {
          colourTheme = 'YlOrRd'
        } else if (climateLayer.variable.includes('growing')) {
          colourTheme = 'YlGn'
        }

        console.log('Climate Layer Domain:', domain)
        console.log('climate layer:', climateLayer)

        const colourScale = chroma.scale(colourTheme).domain(domain)

        this.$store.commit('CLIMATE_COLOUR_THEME', colourTheme)
        this.$store.commit('CLIMATE_DOMAIN', domain)

        for (const cell of data) {
          colourIndex[cell.id] = colourScale(cell[`${climateLayer.variable}_${climateLayer.modelRange}_${climateLayer.timePeriod}`]).rgba()
        }

        const styleFunction = (feature) => {
          return new Style({
            stroke: new Stroke({
              color: 'rgba(80, 80, 80, 0.05)',
              width: 0
            }),
            fill: new Fill({
              color: `rgba(${colourIndex[feature.get('id')]})`
            })
          })
        }

        // If not only changing a layer style, else only style the current climate layer to match the data
        if (!styleOnly) {
          climateLayer = new VectorLayer({
            className: 'bm-multiply',
            name: `climate-${climateLayer.rcp}-${climateLayer.variable}-${climateLayer.timePeriod}`,
            type: 'climate-csv',
            ignoreInfo: false,
            ignorePointer: false,
            source: new VectorSource({ url: '/layers/bcs_anusplingrid.json', format: new TopoJSON() }),
            style: styleFunction
          })
          this.activeClimateLayerOl = climateLayer
          this.mapEl.addLayer(climateLayer)
        } else {
          this.activeClimateLayerOl.setStyle(styleFunction)
          this.activeClimateLayerOl.set('name', `climate-${climateLayer.rcp}-${climateLayer.variable}-${climateLayer.timePeriod}`)
        }


      })
    },

    downloadPopup (evt) {
      const features = []
      this.mapEl.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer.get('name') === 'download') { features.push(feature) }
      })

      if (features.length > 0) {
        let coordinates
        features.forEach(feature => {
          this.$matomo && this.$matomo.trackEvent('Map', 'Download Popup Shown', feature.get('id'))
          coordinates = evt.coordinate
          console.log(feature)
          this.currentInfos.push({
            layerName: 'Download Climate Data',
            
          //Old data Paths
            // description: `
            // <div class="bg-green p-2 m-1"><a class="text-white no-underline" href="/climate-data/BCS_ClimateData_RCP45_${feature.get('id')}.csv"><i class="material-icons material-link pr-1"> link </i>RCP 4.5: Lower Carbon</a></div>
            // <div class="bg-green p-2 m-1"><a class="text-white no-underline" href="/climate-data/BCS_ClimateData_RCP85_${feature.get('id')}.csv"><i class="material-icons material-link pr-1"> link </i>RCP 8.5: Higher Carbon</a></div>
            // `

          ///New data paths --update
            description: `
            <div class="bg-green p-2 m-1"><a class="text-white no-underline" href="/climate-data/BCS_ClimateData_SSP245_${feature.get('id')}.csv"><i class="material-icons material-link pr-1"> link </i>SSP 4.5: Lower Emissions</a></div>
            <div class="bg-green p-2 m-1"><a class="text-white no-underline" href="/climate-data/BCS_ClimateData_SSP585_${feature.get('id')}.csv"><i class="material-icons material-link pr-1"> link </i>SSP 8.5: Higher Emissions</a></div>
            `
          })
        })
        this.popup.setPosition(coordinates)
        return true
      }
    },

    addDownloadLayer () {
      console.log('Triggered Download Layer!')
      this.$matomo && this.$matomo.trackEvent('Map', 'Add Layer', 'Download Data')

      gridDownloadLayer = new VectorLayer({
        name: 'download',
        ignoreInfo: false,
        ignorePointer: false,
        source: new VectorSource({ url: '/layers/bcs_anusplingrid.json', format: new TopoJSON() }),
        style: gridDownloadStyle
      })

      gridDownloadLayer.setZIndex(50)

      this.mapEl.addLayer(gridDownloadLayer)
    },

    actionsPopup (evt) {
      const features = []
      this.mapEl.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer.get('name') === 'actions') { features.push(feature) }
      })

      if (features.length > 0) {
        let coordinates
        features.forEach(feature => {
          coordinates = feature.getGeometry().getCoordinates()
          this.currentInfos.push({
            layerName: `Action: ${feature.get('title')}`,
            description: feature.get('description'),
            link: feature.get('link')
          })
        })
        this.popup.setPosition(coordinates)
        return true
      }
    },

    addActionsLayer () {
      const features = []
      this.actionsFiltered.forEach(item => {
        item.impactArea.forEach(impact => {
          item.lonlat.forEach(marker => {
            if (marker.lonlat.length > 1) {
              const markerFeature = new Feature({
                geometry: new Point(fromLonLat([
                  marker.lonlat[0] + (Math.random() * 0.015 * (Math.random() < 0.5 ? -1 : 1)),
                  marker.lonlat[1] + (Math.random() * 0.015 * (Math.random() < 0.5 ? -1 : 1))
                ])),
                location: marker.location,
                title: item.title,
                link: item.link,
                description: item.description,
                type: item.type,
                impactArea: item.impactArea
              })
              if (impact === 'Homes, Buildings & Infrastructure') {
                markerFeature.setStyle(infrastructureMarkerStyle)
              } else if (impact === 'Economy') {
                markerFeature.setStyle(economyMarkerStyle)
              } else if (impact === 'Quality of Life') {
                markerFeature.setStyle(qolMarkerStyle)
              } else if (impact === 'Nature') {
                markerFeature.setStyle(natureMarkerStyle)
              } else {
                markerFeature.setStyle(communityMarkerStyle)
              }
              features.push(markerFeature)
            }
          })
        })
      })

      const actionSource = new VectorSource({
        features: features
      })

      // let clusterSource = new Cluster({
      //   distance: 10,
      //   source: actionSource
      // })

      actionsLayer = new VectorLayer({
        name: 'actions',
        renderMode: 'vector',
        updateWhileInteracting: true,
        ignoreInfo: true,
        source: actionSource
      })

      actionsLayer.setZIndex(11)

      this.mapEl.addLayer(actionsLayer)
      // this.mapEl.on('click', this.actionsPopup)
    },

    removeActionsLayer () {
      this.mapEl.removeLayer(actionsLayer)
    },

    closePopup (overlay) {
      overlay.setPosition(undefined)
    },

    infoClickHandler (event) {
      // reset current info and current page
      this.currentInfos = []
      this.infoPage = 0
      
      let poppedUp
      if (this.showActionsLayer) {
        this.popup.setOffset([2, -25])
        poppedUp = this.actionsPopup(event)
      }

      if (this.showCommunitiesLayer) {
        this.popup.setOffset([2, -25])
        poppedUp = this.communitiesPopup(event)
      }

      if (this.showDownloadGridLayer) {
        this.popup.setOffset([0, 0])
        poppedUp = this.downloadPopup(event)
      }

      console.log('Info Click Handler Fired:', event)
      const pixel = this.mapEl.getEventPixel(event.originalEvent)

      const layers = []
      this.mapEl.forEachLayerAtPixel(
        pixel,
        layer => {
          layers.push(layer)
        },
        {
          layerFilter: layer => { return layer.get('ignoreInfo') !== true },
          hitTolerance: 0
        }
      )
      console.log('Layers Clicked:', layers)

      // for ArcGIS Map Server Info lookup
      if (layers.filter(x => x.get('type') === 'MapServer').length > 0) {
        const clickCoordinate = this.mapEl.getCoordinateFromPixel(pixel)
        const mapServerInfo = []
        layers.filter(x => { return x.get('type') === 'MapServer' }).forEach(layer => {
          const url = layer.getSource().getUrl()
          const id = layer.getSource().getParams().layers.replace('show:', '')
          const server = mapServerInfo.find(server => server.url === url)
          if (!server) {
            mapServerInfo.push({ url: url, layers: [id] })
          } else {
            mapServerInfo.find(server => server.url === url).layers.push(id)
          }
        })

        console.log('Request for:', layers)

        const requestUrls = mapServerInfo.map(server => {
          const query = {
            geometry: clickCoordinate,
            geometryType: 'esriGeometryPoint',
            sr: 3857,
            layers: `all:${server.layers}`,
            tolerance: 5,
            mapExtent: this.mapEl.getView().calculateExtent(),
            imageDisplay: `${this.mapEl.getSize()},72`,
            returnGeometry: false,
            f: 'json'
          }
          const url = new URL(`${server.url}/identify`)
          url.search = new URLSearchParams(query)
          return url
        })

        Promise.all(requestUrls.map(url => fetch(url)))
          .then(responses => Promise.all(responses.map(response => response.json())))
          .then(response => {
            const mergedResponses = []
            response.forEach(result => mergedResponses.push(...result.results))
            this.currentInfos.push(...mergedResponses)
            if (this.currentInfos.length > 0) {
              this.popup.setOffset([0, 0])
              this.popup.setPosition(clickCoordinate)
            }
          })
      } else if (!poppedUp) {
        this.closePopup(this.popup)
      }

      if (layers.filter(x => x.get('type') === 'climate-csv').length > 0) {
        const clickCoordinate = this.mapEl.getCoordinateFromPixel(pixel)

        const features = []
        this.mapEl.forEachFeatureAtPixel(pixel, (feature, layer) => {
          if (layer.get('type') === 'climate-csv') { features.push(feature) }
        })
        console.log('Features', features)
        
        for (const feature of features) {
          console.log('Feature', feature)

          // dataRow variable is undefine, error when getting id --update
          console.log('current climate data: ', this.currentClimateData)
          console.log('current climate data: ', this.currentClimateData.filter(x => x.id === String(feature.get('id'))))
          
          const dataRow = this.currentClimateData.filter(x => x.id === String(feature.get('id')))[0]
          console.log('dataRow', dataRow)
          
          const value = dataRow[`${this.$store.state.climateVariable}_${this.$store.state.climateRange.toLowerCase()}_${this.$store.getters.climatePeriod}`]
          
          const diff = this.$store.getters.climatePeriod !== '1960s' 
            ? dataRow[`${this.$store.state.climateVariable}_${this.$store.state.climateRange.toLowerCase()}_${this.$store.getters.climatePeriod}_diff`] 
            : false

          this.currentInfos.push({
            layerName: this.$store.getters.getClimateTitle,
            attributes: {
              [`${this.$store.state.climatePeriod}`]: `${value.split('.')[1] > 3 ? parseFloat(value).toFixed(2) : value} ${this.$store.getters.getClimateUnit}`, 
              ...(diff && {'Difference from 1960s': `${diff > 0 ? '+' : ''}${diff.split('.')[1] > 3 ? parseFloat(diff).toFixed(2) : diff} ${this.$store.getters.getClimateUnit}`}) // conditional, only if diff is available
            }
          })

          console.log('Row:', value, diff)
        }

        this.popup.setOffset([0, 0])
        this.popup.setPosition(clickCoordinate)
      }
    },

    infoPaginate (direction) {
      if (direction === 'forward') {
        if (this.infoPage + 1 > this.currentInfos.length - 1) {
          this.infoPage = 0
        } else { this.infoPage += 1 }
      } else {
        if (this.infoPage - 1 < 0) {
          this.infoPage = this.currentInfos.length - 1
        } else { this.infoPage += -1 }
      }
    },

    zoomTo (lon, lat, zoom) {
      console.log(lon, lat, zoom)
      const view = this.mapEl.getView()
      view.setCenter(fromLonLat([lon, lat]))
      view.setZoom(zoom)
    },

    mapReset () {
      this.mapEl.getView().fit([-13447549.400204774, 6274861.3816, -12696457.7845, 6960959.310699999], {
        constrainResolution: false,
        padding: [15, 50, 10, 10]
      })

      this.closePopup(this.popup) // make sure popups are closed
      
      const layers = this.activeLayers.filter(layer => layer.get('id') !== 13 && layer.get('type') !== 'climate-csv')
      layers.forEach(layer => {
        console.log('Removing', layer)
        this.$store.commit('LAYER_VISIBILITY', {id: layer.get('id'), state: false })
        this.removeLayer(layer.get('id'))
      })

      this.$store.commit('CLIMATE_VARIABLE', undefined)
      this.$store.commit('CLIMATE_SCENARIO', 'Higher Global Emissions')
      this.$store.commit('CLIMATE_PERIOD', '2050s')
      this.$store.commit('CLIMATE_RANGE', 'Median')
      this.$nextTick(() => { this.$router.replace({name: this.$route.name, query: { range: undefined, period: undefined, scenario: undefined } }).catch(() => {}) })
    },

    valueFormatter (value) {
      if (!isNaN(value)) {
        return `${Math.round(value * 100) / 100} ${this.$store.getters.getClimateUnit}`
      } else {
        return value
      }
    }
  },
  watch: {
    activeClimateLayer (newLayer, oldLayer) {
      if (newLayer) {
        this.closePopup(this.popup)
        const oldOl = this.activeClimateLayerOl

        console.log('Adding climate layer:', newLayer)
        if ( this.currentClimateData.length > 0 ) // if there is already climate data
        {
          this.addClimateDataLayer(newLayer, true)
        } else {
          this.addClimateDataLayer(newLayer)
          if (oldOl) {
            console.log('Removing climate layer:', oldOl)
            this.mapEl.removeLayer(oldOl)
          }
        }
        this.$matomo && this.$matomo.trackEvent('Map', 'Add Climate Layer', this.activeClimateLayer.layer)
        
      } else { // Allow for Unselecting the climate layer
        console.log('Removing climate layer:', oldLayer)
        this.mapEl.removeLayer(this.activeClimateLayerOl)
        this.currentClimateData = []
      }
    },
    activeTimeLayers: {
      handler: function () {
        this.activeTimeLayers.forEach(layer => {
          this.removeLayer(layer.id)
          this.addLayer(layer.id)
        })
      },
      deep: true
    },
    actionsFiltered () {
      if (this.showActionsLayer && this.$route.name === 'actions') {
        this.removeActionsLayer()
        this.addActionsLayer()
      }
    },
    showActionsLayer (value) {
      if (value === true) {
        this.addActionsLayer()
      } else if (value === false) {
        this.mapEl.removeLayer(actionsLayer)
        if (this.popup.getPosition() !== undefined) { this.closePopup(this.popup) }
      }
    },
    showGridLayer (value) {
      if (value === true) {
        this.addGridLayer()
      } else if (value === false) {
        this.mapEl.removeLayer(gridLayer)
        if (this.popup.getPosition() !== undefined) { this.closePopup(this.popup) }
      }
    },
    showDownloadGridLayer (value) {
      if (value === true) {
        this.addDownloadLayer()
      } else if (value === false) {
        this.mapEl.removeLayer(gridDownloadLayer)
        if (this.popup.getPosition() !== undefined) { this.closePopup(this.popup) }
      }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.mapEl.updateSize()
    })
  },
  components: {
  }
}
</script>

<style scoped>
#map-container {
  overflow: hidden;
}

#map {
  position: relative;
}
</style>

<style lang="scss">
.bm-multiply {
  mix-blend-mode: multiply;
}

.ol-control {
  border-radius: 0;
  padding: 0;
  background-color: rgb(220, 220, 220);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ol-control:hover {
  background-color: rgb(220, 220, 220);
}

.ol-zoom {
  top: 0.5em;
  right: 0.5em;
  left: inherit;
  font-size: 1.13rem;
}

.ol-zoom .ol-zoom-in {
  border-radius: 0;
  margin-bottom: 1px;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0;
}

.ol-control button {
  background-color: white;
  min-height: 0;
  color: rgb(120, 120, 120);
  border-radius: 0;
  margin: 0;
  height: 2em;
  width: 2em;
}

.ol-control button:focus {
  background-color: white;
  color: rgb(20, 20, 20);
  min-height: 0;
}
.ol-control button:hover {
  background-color: white;
  min-height: 0;
  color: rgb(20, 20, 20);
}

#popup-content {
  width: 100%;
}

.ol-attribution {
  right: unset;
  left: 0.5em;
  background: rgba(255,255,255,0.2) !important;
  box-shadow: unset;

  button {
    display: none;
  }
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  /* padding: 15px; */
  /* border-radius: 10px; */
  /* border: 1px solid #cccccc; */
  bottom: 12px;
  left: -50px;
  /* min-width: 80px; */
  width: max-content;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 0px;
  right: 5px;
}
.ol-popup-closer:after {
  color: white;
  content: "✖";
}

.popup-pager {
  background-color: white;
  position: absolute;
  right: 0px;
  user-select: none;
}


.popup-content,
.popup-title,
.popup-attributes {
  max-width: 22rem;
  box-sizing: content-box;
}

.popup-attributes {
  table {
    thead {
      @apply font-bold;
    }
    tbody {
      padding-top: 0.25rem;

      td {
        padding-top: 0.15rem;
      }
    }
    .attribute {
      max-width: 8rem;
      padding-right: 1rem;
    }
  }
}

.material-link {
  @apply align-bottom; 
  @apply text-lg;
  margin-top: -3px;
  transform: rotate(-45deg);
}

</style>
