var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isFetching && _vm.isDisplayed)?_c('div',{staticClass:"legend bg-grey p-2"},[_c('ul',[_c('transition-group',{attrs:{"name":"slide-fade"}},[_vm._l((_vm.activeLayers),function(layer){return _c('LegendItem',{key:layer.id,attrs:{"legendObject":_vm.getLegendObject(layer),"layerName":layer.name}})}),(_vm.activeLayers.find(function (x) { return x.id === 3; }) !== undefined)?_c('LegendItem',{key:"999999",attrs:{"layerName":"High Uncertainty Indicator","legendObject":[
            {
              "label": "MD > 1.1",
              "url": "8463507cd953d2f701e7f911a9ecb756",
              "imageData": "iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IB2cksfwAAAAlwSFlzAAATrwAAE68BY+aOwwAAAE1JREFUSInt0zEOACAIA0BI+P+XdXJDRNM0Du3YgRuAMFJCkCBBdGgknaOhDFn9EetCO6SNfbsjQdeQW30QsKurMPgftYcioOcIEsSHJhDNB2oObKK7AAAAAElFTkSuQmCC",
              "contentType": "image/png",
              "height": 26,
              "width": 26
            }
          ]}}):_vm._e()],2)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }