<template>
  <div id="map-layer-icons" class="top-0 left-0 z-10 absolute">
    <div v-for="layer in mapLayers" :key="layer.id" class="flex items-stretch">
      <!-- bg-red bg-orange bg-blue bg-green bg-purple bg-yellow bg-indigo bg-indigo-dark bg-blue-light bg-teal bg-grey-darker -->
      <div :class="`bg-${layer.enabled ? layer.color : `grey-darker`} hover:bg-${layer.color} w-8 h-8 flex justify-center items-center item-patch`"
           @click="handleLayerToggle(layer)">
        <img :src='require(`@/assets/images/maplayers/${layer.icon}.svg`)'/>
      </div>
      <div :class="`px-1 item-name flex items-center bg-${layer.color}`">
        <div>{{layer.name}}</div>
        <div v-if="layer.description"
        :class="`description text-sm px-1 pb-2 w-full bg-${layer.color}`"
        v-html="layer.description">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    mapLayers () { return this.$store.state.mapLayers }
  },
  methods: {
    handleLayerToggle (layer) {
      if (layer.enabled === true) {
        // this.enabled = false
        this.$store.commit('LAYER_VISIBILITY', { id: layer.id, state: false })
        this.$root.$emit('map/layer-disable', layer.id)
        // this.$store.commit('SHOW_MAP_LAYER_ICON', true)
/////  this would make the grey bar disapear when a icon is toggled off
      } else {
        this.$store.commit('LAYER_VISIBILITY', { id: layer.id, state: true })
        this.$root.$emit('map/layer-enable', layer.id)
        // this.$store.commit('SHOW_MAP_LAYER_ICON', true)
      }
    }
  }
}
</script>
<style lang="scss">
#map-layer-icons {
  a,
  a:visited,
  a:hover {
    color: white;
  }
}
</style>

<style lang="scss" scoped>
#map-layer-icons {
  top: 8.5rem;
  pointer-events: none;
  z-index: 15;

  &.mobile {
    top: 0.5rem;
  }

  img {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }

  div {
    margin-top: 0rem;
  }

  .item-patch {
    pointer-events: auto;
    cursor: pointer;
  }

  .item-name {
    // display: none;
    min-width: 8rem;
    visibility: hidden;
    color: white;
    position: relative;

    .description {
      position: absolute;
      top: 2rem;
      left: 0;
    }
  }

  .item-patch:hover + .item-name,
  .item-name:hover {
    // display: flex;
    pointer-events: auto;
    visibility: visible;
  }
}
</style>
