<template>
  <div id="footer" class="z-10 bg-green-dark align-middle flex justify-between font-condensed">
    <div class="pl-0 md:pl-2 text-white">
      <vue-goodshare-facebook has_icon :page_url="location" />
      <vue-goodshare-twitter has_icon :page_url="location" />
    </div>
    <div class="pr-1 pt-1 md:pr-2 footer-links text-xs md:text-base">
      <router-link to="/about-data">About the Data</router-link> |
      <router-link to="/about">About the Site</router-link> |
      <router-link to="/privacy">Privacy Statement</router-link> |
      <router-link to="/feedback">Feedback</router-link>
    </div>
  </div>
</template>

<script>
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter'

export default {
  components: {
    VueGoodshareFacebook,
    VueGoodshareTwitter
  },
  data () {
    return {
      location: window.location.href
    }
  }
}
</script>

<style lang="scss">
#footer {
  // line-height: 30px;
  @apply text-green uppercase;
  overflow: hidden;
}

.footer-links {
  a {
    @apply text-green uppercase no-underline;
  }
}

.button-social {
  padding: 0 5px !important;
  line-height: 23px;
  filter: grayscale(100);
  opacity: 0.5;
  @apply text-sm;

  &:hover {
    opacity: 100;
    filter: none;
  }
}
</style>
