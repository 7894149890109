<template>
  <div class="slide relative">
    <img class="slide__img" :src="image()" />
    <p class="teaser">
      {{ teaser }}
    </p>
    <img class="slide__overlay absolute" v-if="icon" :src="icon()" />
  </div>
</template>
<script>
export default {
  components: {
  },
  computed: {},
  methods: {
    getImgUrl(img) {
      return require(`@/assets/images/gateway/${img}`)
    },
  },
  props: [
    'teaser', 'image', 'link', 'icon'
  ]
}
</script>
<style scoped lang="scss">
.slide__overlay {
  top: 0;
  left: 0;
  transform: scale(0.4) translateY(-20%);
}

.slide {
  $green : rgb(43, 162, 73);
  $radius: 10px;
  display: grid;
  border: 1px solid $green;
  grid-template-rows: auto 75px;
  align-content: end;
  font-weight: inherit;
  position: relative;
  border-radius: $radius;
  background: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  .teaser {
    display: flex;
    grid-row: 2;
    flex-direction: column;
    place-content: center;
    z-index: 10;
    color: white;
    background-color: $green;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: Open Sans Condensed, Open Sans, sans-serif;
    vertical-align: middle;
    text-align: center;
    color: white;
    letter-spacing: 1.5px;

    @media (max-width: 600px) {
      font-size: 1.0rem;
    }
  }

  .slide__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    border-radius: $radius;
  }
}
</style>
