<template>
  <div class="time-control-wrapper pb-2 bg-green-medium">
    <div class="slider-label"><slot></slot></div>
    <vue-slider ref="slider" v-bind="slider" v-model="value"></vue-slider>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component'

export default {
  components: { vueSlider },
  props: {
    options: Array,
    layer: Object,
    defaultOption: String
  },
  data () {
    return {
      // value: this.defaultOption,
      slider: {
        width: '80%',
        height: 10,
        dotSize: 23,
        tooltip: false,
        disabled: false,
        piecewise: false,
        piecewiseLabel: true,
        style: {
          marginLeft: '10%'
        },
        data: this.options,
        piecewiseStyle: {
          backgroundColor: '#1C4721',
          visibility: 'visible',
          width: '22px',
          height: '22px'
          // marginTop: '-1px'
        },
        sliderStyle: [
          {
            backgroundColor: '#fff'
          },
          {
            backgroundColor: '#34B44D'
          }
        ],
        bgStyle: {
          backgroundColor: '#F2F3F2',
          border: 'solid 1px #919291',
          cursor: 'pointer'
        },
        piecewiseActiveStyle: { backgroundColor: '#1C4721' },
        labelActiveStyle: { color: '#ffffff' },
        labelStyle: { color: '#ffffff' },
        processStyle: { backgroundColor: '#F2F3F2' }
      }
    }
  },
  computed: {
    value: {
      get: function () { return this.$store.getters.activeTimeLayerFrame(this.layer.id) },
      set: function (newValue) {
        this.$store.commit(
          'LAYER_TIME_ACTIVE', {
            id: this.layer.id,
            frame: this.$store.getters.activeTimeLayerFrame(this.layer.id),
            status: false
          }
        )
        this.$store.commit(
          'LAYER_TIME_ACTIVE', {
            id: this.layer.id,
            frame: newValue,
            status: true
          }
        )
      }
    }
  },
  watch: {
    // value: function (newValue, oldValue) {
    //   this.$store.commit(this.storeMutation, newValue)
    // }
  }
}
</script>

<style scoped lang="scss">
.slider-label {
  text-align: center;
  padding-top: 1rem;
  color: white;
}

.time-control-wrapper {
  width: 18rem;
}
</style>
