<template>
  <vimeo-player class="vplayer bg-black" ref="vplayer" :player-height="nh" :player-width="width"
    v-bind:video-id="src.id" />
</template>
<script>
import videos from '@/content/videos.json'
export default {
  computed: {
    nh() {
      return this.height;
    },
  },
  props: ['openned', 'width', 'height'],
  watch: {
  },
  methods: {
    isMobile() {
      if (window.innerWidth < 768) {
        return true
      } else {
        return false
      }
    },
  },
  mounted: function () {
  },

  data() {
    return {
      src: this.isMobileView() ? videos['mobile'] : videos['desktop']
    }
  },
}
</script>
<style lang="scss" scoped>
.vplayer {}
</style>
