<template>
  <div id="nav-bar" class="relative bg-green w-100 text-center font-extrabold">
    <slot />
    <div class="flex items-center justify-center cursor-pointer">
      <router-link :to="{ name: 'home' }">
        <img class="logo" src="@/assets/images/logo.svg" />
      </router-link>
    </div>
    <div id="nav" class="nav-menu uppercase pb-2 font-light leading-normal md:leading-none md:mt-3 "
      v-intro="'Read about how climate change will impact individual communities; impacts to our regional economy, nature, infrastructure and quality of life; what actions we\'re taking in our region; and finally, learn about how climate science is conducted and common myths.'"
      v-intro-step="1" v-intro-highlight-class="'intro-dark'">
      <router-link to="/communities">Communities</router-link>
      <router-link to="/impacts">Impacts</router-link>
      <router-link to="/actions">Actions</router-link>
      <router-link class="block sm:inline " to="/climate-science">Climate&nbsp;Science</router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.logo {
  width: 350px;
  height: 105px;
}

#nav-bar {
  a {
    color: inherit;
    text-decoration: none;
  }

  .title {
    width: 18rem;
  }
}

#nav {
  padding: 1rem 2rem 0.5rem;

  a {
    @apply text-white no-underline text-lg;
    width: max-content;

    &.router-link-active {
      @apply text-white no-underline border-solid border-white border-b-2;
    }
  }
}

svg {
  vertical-align: middle;

  &.size-medium {
    width: 70px;
    height: 70px;
  }
}

@media (min-width: 768) {
  #nav {
    a:last-of-type {
      margin-right: 0px;
    }
  }
}

.nav-menu {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  width: 100%;
  scale: 0.8;
  font-family: Lato, Calibri, Arial, sans-serif;

}
</style>
