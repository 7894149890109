<template>
  <div id="app" class="w-full h-full md:flex flex-col font-sans text-black">

    <router-link to="/feedback">
      <notifications group="notify" classes="my-style" />
    </router-link>


    <div class="flex flex-row md:overflow-hidden bg-grey md:h-full app-container">
      <!-- Mobile Map -->
      <div v-if="isMobile" :class="{ hide: !showMobileMap }" class="mobile-map-container fixed md:hidden
              left-0 top-0 z-50 w-full h-full">
        <div class="mobile-map w-full h-full bg-green-dark">
          <AppMap>
            <MapLayerIcons v-show="showMapControls" class="mobile"
              v-intro="'Enable non-climate layers by clicking the icons here.'" v-intro-step="9"
              v-intro-highlight-class="'intro-none'" />

            <div title="Toggle Map Controls" class="tr-controls z-50 absolute cursor-pointer text-center"
              :class="showMapControls ? 'bg-green' : 'bg-green-medium'" @click="$store.commit('CONTROL_TOGGLE')">
              <i class="material-icons text-white" v-if="showMapControls">fullscreen</i>
              <i class="material-icons text-white" v-if="!showMapControls">fullscreen_exit</i>
            </div>

            <div v-show="showMapControls" class="bl-controls bottom-0 right-0 z-10 absolute flex flex-col items-end mb-8">
              <div class="legend-container mb-2">
                <Legend />
              </div>
              <div class="legend-container mb-2">
                <LegendClimate v-if="$store.getters.activeClimateLayer" />
              </div>
              <div v-show-slide="$store.getters.activeTimeLayers.length > 0" class="time-container bg-green mb-2">
                <TimeControl v-for="layer in $store.getters.activeTimeLayers"
                  :options="layer.timeFrames.map(timeFrame => timeFrame.frame)"
                  :defaultOption="$store.getters.activeTimeLayerFrame(layer.id)" :layer=layer :key="layer.id">
                  {{ layer.name }}
                </TimeControl>
              </div>
            </div>
          </AppMap>
        </div>
        <div v-show="showMapControls" class="mobile-map-toggle-container font-extrabold text-center">
          <div
            class="bg-grey mx-auto p-2 cursor-pointer font-condensed uppercase text-green-dark border-green-dark border-t-2 border-l-2 border-r-2 border-solid"
            @click="toggleMobileMap(false)">▲ Hide Map ▲</div>
          <div
            class="bg-grey mx-auto p-2 cursor-pointer font-condensed uppercase text-green-dark border-green-dark border-b-2 border-l-2 border-r-2 border-solid"
            @click="toggleMobileMap(true)">▼ Show Map ▼</div>
        </div>
      </div>

      <Sidebar id="sidebar" class="w-full md:flex-fixed md:flex flex-col z-10" v-show="showMapControls">
        <Navigation />

        <div v-show-slide="showLayers">
          <div class="overflow-auto h-full p-4 pl-10 pr-10 bg-green-medium text-white">
            <div class="text-center align-center pb-3">
              <span v-tippy='{ placement: "top" }'
                title="Choose from over 40 climate change indicators in five categories">Climate Indicators</span>
            </div>
            <LayersList>
              <div class="climate-layers">
                <div id="climate-layer-container" v-intro="'Choose a Climate Section and Climate Layer'" v-intro-step="3">

                  <div class="climate-layer-section-picker" id="climate-layer-section-picker">

                    <div class="hc" @click="activateClimateSection('Hot and Cold Weather')">
                      <div> <img src='@/assets/images/climatelayers/hot_cold.svg' /> </div>
                      <span class="hidden md:inline">Hot and Cold Weather</span>
                    </div>
                    <div class="at" @click="activateClimateSection('Average Temperature')">
                      <div> <img src='@/assets/images/climatelayers/average_temp.svg' /> </div>
                      <span class="hidden md:inline">Average Temperature</span>
                    </div>
                    <div class="gs" @click="activateClimateSection('Growing Season')">
                      <div> <img src='@/assets/images/climatelayers/growing_season.svg' /> </div>
                      <span class="hidden md:inline">Growing Season</span>
                    </div>
                    <div class="ap" @click="activateClimateSection('Average Precipitation')">
                      <div> <img src='@/assets/images/climatelayers/average_precip.svg' /> </div>
                      <span class="hidden md:inline">Average Precipitation</span>
                    </div>
                    <div class="ep" @click="activateClimateSection('Extreme Precipitation')">
                      <div> <img src='@/assets/images/climatelayers/extreme_precip.svg' /> </div>
                      <span class="hidden md:inline">Extreme Precipitation</span>
                    </div>
                  </div>

                  <div v-show-slide="climateSection === 'Hot and Cold Weather'" class="climate-layer-section bg-red">
                    <climate-layer type="hot" name="frost_days_annual">Frost Days (Annual)</climate-layer>
                    <climate-layer type="hot" name="hwmtxp_annual">Heat Wave Magnitude (Annual)</climate-layer>
                    <climate-layer type="hot" name="hwntxp_annual">Heat Wave Number (Annual)</climate-layer>
                    <climate-layer type="hot" name="heating_degree_days_annual">Heating Degree Days
                      (Annual)</climate-layer>
                    <climate-layer type="hot" name="cooling_degree_days_annual">Cooling Degree Days
                      (Annual)</climate-layer>
                    <climate-layer type="hot" name="ice_days_annual">Ice Days (Annual)</climate-layer>
                    <climate-layer type="hot" name="tnltm20_annual">Very Cold Nights (Annual)</climate-layer>
                    <climate-layer type="hot" name="summer_days_annual">Summer Days (Annual)</climate-layer>
                    <climate-layer type="hot" name="tropical_nights_annual">Tropical Nights (Annual)</climate-layer>
                    <climate-layer type="hot" name="maximum_temperature_annual">Maximum Temperature
                      (Annual)</climate-layer>
                    <climate-layer type="hot" name="lowest_temperature_annual">Lowest Temperature (Annual)</climate-layer>
                  </div>

                  <div v-show-slide="climateSection === 'Average Temperature'" class="climate-layer-section bg-orange">
                    <climate-layer type="avg" name="mean_temperature_annual">Mean Temperature (Annual)</climate-layer>
                    <climate-layer type="avg" name="mean_temperature_spring">Mean Temperature (Spring)</climate-layer>
                    <climate-layer type="avg" name="mean_temperature_summer">Mean Temperature (Summer)</climate-layer>
                    <climate-layer type="avg" name="mean_temperature_fall">Mean Temperature (Fall)</climate-layer>
                    <climate-layer type="avg" name="mean_temperature_winter">Mean Temperature (Winter)</climate-layer>

                    <climate-layer type="avg" name="mean_maximum_temperature_annual">Mean Maximum Temperature
                      (Annual)</climate-layer>
                    <climate-layer type="avg" name="mean_maximum_temperature_spring">Mean Maximum Temperature
                      (Spring)</climate-layer>
                    <climate-layer type="avg" name="mean_maximum_temperature_summer">Mean Maximum Temperature
                      (Summer)</climate-layer>
                    <climate-layer type="avg" name="mean_maximum_temperature_fall">Mean Maximum Temperature
                      (Fall)</climate-layer>
                    <climate-layer type="avg" name="mean_maximum_temperature_winter">Mean Maximum Temperature
                      (Winter)</climate-layer>

                    <climate-layer type="avg" name="mean_minimum_temperature_annual">Mean Minimum Temperature
                      (Annual)</climate-layer>
                    <climate-layer type="avg" name="mean_minimum_temperature_spring">Mean Minimum Temperature
                      (Spring)</climate-layer>
                    <climate-layer type="avg" name="mean_minimum_temperature_summer">Mean Minimum Temperature
                      (Summer)</climate-layer>
                    <climate-layer type="avg" name="mean_minimum_temperature_fall">Mean Minimum Temperature
                      (Fall)</climate-layer>
                    <climate-layer type="avg" name="mean_minimum_temperature_winter">Mean Minimum Temperature
                      (Winter)</climate-layer>
                  </div>

                  <div v-show-slide="climateSection === 'Growing Season'" class="climate-layer-section bg-green-alt">
                    <climate-layer type="grow" name="accumulated_moisture_annual">Accumulated Moisture
                      (Annual)</climate-layer>
                    <climate-layer type="grow" name="growing_season_length_annual">Growing Season Length
                      (Annual)</climate-layer>
                  </div>

                  <div v-show-slide="climateSection === 'Extreme Precipitation'" class="climate-layer-section bg-blue">
                    <climate-layer type="expri" name="r20mm_annual">Very Heavy Rain Days (Annual)</climate-layer>
                    <climate-layer type="expri" name="r10mm_annual">Heavy Rain Days (Annual)</climate-layer>
                    <climate-layer type="expri" name="maximum_1_day_precipitation_annual">Maximum 1-day Precipitation
                      (Annual)</climate-layer>
                    <climate-layer type="expri" name="maximum_1_day_precipitation_spring">Maximum 1-day Precipitation
                      (Spring)</climate-layer>
                    <climate-layer type="expri" name="maximum_1_day_precipitation_summer">Maximum 1-day Precipitation
                      (Summer)</climate-layer>
                    <climate-layer type="expri" name="maximum_1_day_precipitation_fall">Maximum 1-day Precipitation
                      (Fall)</climate-layer>
                    <climate-layer type="expri" name="maximum_1_day_precipitation_winter">Maximum 1-day Precipitation
                      (Winter)</climate-layer>
                  </div>

                  <div v-show-slide="climateSection === 'Average Precipitation'" class="climate-layer-section bg-teal">
                    <climate-layer type="wet" name="precipitation_total_annual">Total Precipitation
                      (Annual)</climate-layer>
                    <climate-layer type="wet" name="precipitation_total_spring">Total Precipitation
                      (Spring)</climate-layer>
                    <climate-layer type="wet" name="precipitation_total_summer">Total Precipitation
                      (Summer)</climate-layer>
                    <climate-layer type="wet" name="precipitation_total_fall">Total Precipitation (Fall)</climate-layer>
                    <climate-layer type="wet" name="precipitation_total_winter">Total Precipitation
                      (Winter)</climate-layer>
                  </div>
                </div>

                <Slider :options="climateLayers.changeSlider.options" :defaultOption="$store.state.climateScenario"
                  storeMutation="CLIMATE_SCENARIO"
                  v-intro="'Pick a future global emissions amount: Lower global emissions indicates strong global carbon mitigation (SSP2-4.5), higher global emissions indicates fossil-fueled development (SSP5-8.5)'"
                  v-intro-highlight-class="'intro-dark'" v-intro-step="4">
                  <span
                    title="Lower global emissions indicates strong global carbon mitigation (SSP2-4.5), higher global emissions indicates fossil-fueled development (SSP5-8.5)"
                    v-tippy='{ placement: "top" }'>Future Scenario</span>
                </Slider>

                <Slider :options="climateLayers.timePeriodSlider.options" :defaultOption="$store.state.climatePeriod"
                  storeMutation="CLIMATE_PERIOD"
                  v-intro="'Choose a time period: these times use a 30-year average around each decade (i.e.: 2050s are 2040-2070). The baseline is 1951-1980.'"
                  v-intro-step="5" v-intro-highlight-class="'intro-dark'">
                  <span v-tippy='{ placement: "top" }'
                    title="Time periods are 30-year averages around each decade. For example, the 1960s represent 1951-1980, the 2020s represent 2011-2040.">Time
                    Period</span>
                </Slider>

                <Slider :options="climateLayers.modelRangeSlider.options" :defaultOption="$store.state.climateRange"
                  storeMutation="CLIMATE_RANGE"
                  v-intro="'Finally, choose a model range: the data you see is a combination of 7 different models, this slider lets you choose either the lowest, median, or highest of the 7 models.'"
                  v-intro-step="6" v-intro-highlight-class="'intro-dark'">
                  <span v-tippy='{ placement: "top" }'
                    title="Show the lowest, median, or highest value of the 7 climate models">Model Range</span>
                </Slider>

                <DownloadFile> </DownloadFile>


                <div class="text-right text-sm pt-4">
                  <span v-intro="'Enable this option to show the grid that the climate data is drawn from.'"
                    v-intro-step="7" v-intro-highlight-class="'intro-dark'"><input type="checkbox" class="pr-3"
                      @click="handleShowGrid" :checked="showGridLayer" /> Climate Data Grid</span>

                </div>


                <!--  **** OLD BUTTON ******** 
                   useful feature still exists if user wants to download the data for one specific location block, handleDownload will do that.  old 
                  <span
                    v-intro="'Click this button to show a grid that allows you to download <strong>all</strong> climate variables in a scenario for a given 10km cell.'"
                    v-intro-step="8"
                    class="h-4 bg-green ml-3 p-2 cursor-pointer"
                    v-tippy='{placement: "top"}'
                    title="Step 1. Choose climate indice, <br>Step 2. Select scenario, time period and model range, <br>Step 3. Click Download Data, <br>Step 4. Click your desired location on the map, <br>Steo 5. Click on the map pop-up to download data"
                    @click="handleDownload">Download Data
                  </span> -->

              </div>

            </LayersList>
          </div>
        </div>

        <!-- <div class="bg-green-medium py-2 text-center cursor-pointer">
          <span class="text-white font-condensed uppercase">Banner</span>
        </div> -->

        <div class="bg-green-medium py-2 text-center cursor-pointer md:hidden" @click="$store.commit('LAYERS_TOGGLE')">
          <span v-if="!showLayers" class="text-white font-condensed uppercase">▼ Climate Layers ▼</span>
          <span v-if="showLayers" class="text-white font-condensed uppercase">▲ Climate Layers ▲</span>
        </div>

        <div id="scrollable" ref="scrollable" class="content-wrapper overflow-auto flex-1">
          <div class="h-full">
            <router-view />
          </div>
        </div>
      </Sidebar>

      <AppMap class="flex-1 hidden md:block relative" v-show="showMap">
        <LayersToggle id='layersToggle' v-show="showMapControls" v-intro="'Click here to show the Climate Layers'"
          v-intro-step="2" v-tippy='{ placement: "right" }' title="Toggle Climate Layer Controls" />

        <MapLayerIcons v-show="showMapLayerIcons" v-intro="'Enable non-climate layers by clicking the icons here.'"
          v-intro-step="9" v-intro-highlight-class="'intro-none'" />

        <div class="tr-controls z-10 absolute">
          <div title="Reset Map" v-tippy='{ placement: "left" }'
            class="cursor-pointer bg-white shadow-md w-full h-full flex items-center justify-around"
            @click="$root.$emit('map/reset')">
            <i class="material-icons text-gray-600">restart_alt</i>
          </div>
          <div title="Toggle Map Controls and Sidebar" v-tippy='{ placement: "left" }'
            class="cursor-pointer shadow-md w-full h-full mt-1.5 flex items-center justify-around"
            :class="showMapControls ? 'bg-white' : 'bg-green-medium'" @click="$store.commit('CONTROL_TOGGLE')">
            <i class="material-icons text-gray-600" v-if="showMapControls">fullscreen</i>
            <i class="material-icons text-white" v-if="!showMapControls">fullscreen_exit</i>
          </div>
        </div>

        <div v-show="showMapControls"
          class="bl-controls bottom-0 right-0 z-10 absolute mb-8 flex flex-col items-end md:mb-0 pointer-events-none">
          <div class="legend-container mb-2">
            <Legend />
          </div>
          <div class="legend-container mb-2">
            <LegendClimate v-if="$store.getters.activeClimateLayer" />
          </div>
          <div v-show-slide="$store.getters.activeTimeLayers.length > 0" class="time-container bg-green mb-2">
            <TimeControl v-for="layer in $store.getters.activeTimeLayers"
              :options="layer.timeFrames.map(timeFrame => timeFrame.frame)"
              :defaultOption="$store.getters.activeTimeLayerFrame(layer.id)" :layer=layer :key="layer.id">
              {{ layer.name }}
            </TimeControl>
          </div>
        </div>
      </AppMap>

      <div class="flex-1 map-frame h-full hidden md:block" ref="mapCover" v-if="!showMap">
        <MapCover />
      </div>
    </div>
    <AppFooter id="footer" class="w-full" />
  </div>
</template>

<script>
import DownloadFile from '@/components/DownloadFile.vue';
import { mapState } from 'vuex'
import Sidebar from '@/components/Sidebar.vue'
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import Map from '@/components/Map.vue'
import MapLayersToggle from '@/components/MapLayersToggle.vue'
import MapLayersList from '@/components/MapLayersList.vue'
import MapLayerIcons from '@/components/MapLayerIcons.vue'
import Slider from '@/components/Slider.vue'
import ClimateLayerItem from '@/components/ClimateLayerItem.vue'
import MapCover from '@/components/MapCover.vue'
import TimeControl from '@/components/TimeControl.vue'
import Legend from '@/components/Legend.vue'
import LegendClimate from '@/components/LegendClimate.vue'


const meta = {
  title: 'Map',
  description: 'Welcome'
}

function titleCase(str) {
  return str.toLowerCase().split(' ').map(word => {
    return (word.charAt(0).toUpperCase() + word.slice(1))
  }).join(' ')
}

export default {
  metaInfo() {
    return {
      title: meta.title,
      titleTemplate: '%s | Columbia Basin Climate Source',
      meta: [
        {
          property: 'og:description',
          content: meta.description,
          template: chunk => `${chunk} - The Columbia Basin Climate Source covers climate information and resources for the Columbia Basin Boundary Region`,
          vmid: 'og:description'
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type'
        },
        {
          property: 'og:image',
          content: 'https://basinclimatesource.ca/bcs_social_facebook.png',
          vmid: 'og:image'
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        {
          property: 'twitter:title',
          content: meta.title,
          template: chunk => `${chunk} - Columbia Basin Climate Source`,
          vmid: 'twitter:title'
        },
        {
          property: 'twitter:description',
          content: meta.description,
          template: chunk => `${chunk} - The Columbia Basin Climate Source covers climate information and resources for the Columbia Basin Boundary Region`,
          vmid: 'twitter:description'
        },
        {
          property: 'twitter:image',
          content: 'https://basinclimatesource.ca/bcs_social_facebook.png',
          vmid: 'twitter:image'
        }

      ]
    }
  },

  components: {
    DownloadFile,
    Sidebar: Sidebar,
    Navigation: Navigation,
    AppFooter: Footer,
    AppMap: Map,
    LayersToggle: MapLayersToggle,
    LayersList: MapLayersList,
    Slider: Slider,
    ClimateLayer: ClimateLayerItem,
    MapLayerIcons: MapLayerIcons,
    MapCover: MapCover,
    TimeControl: TimeControl,
    Legend: Legend,
    LegendClimate
  },

  data() {
    return {
      climateLayers: {
        changeSlider: { options: ['Lower Global Emissions', 'Higher Global Emissions'] },
        modelRangeSlider: { options: ['Low', 'Median', 'High'] },
        timePeriodSlider: {
          options: [
            '1960s', // 1951-1980
            '2020s', // 2011-2040
            '2030s',
            '2040s',
            '2050s',
            '2060s',
            '2070s', // 2041-2070
            '2080s'] // 2071-2100
        }
      },
      climateSection: null,
      showMobileMap: false
    }
  },
  computed: {
    isMobile() {
      if (window.innerWidth < 768) {
        return true
      } else {
        return false
      }
    },
    windowHeight() { return window.innerHeight },
    windowWidth() { return window.innerWidth },
    mapLayers() { return this.$store.state.mapLayers },
    ...mapState([
      'climateScenario',
      'climatePeriod',
      'climateRange',
      'climateVariable',
      'showMap',
      'showLayers',
      'showGridLayer',
      'showMapControls',
      'showMapLayerIcons'
    ]),
    urlVariable() { return this.$route.query.variable },
    urlRange() { return this.$route.query.range },
    urlScenario() { return this.$route.query.scenario },
    urlPeriod() { return this.$route.query.period }
  },
  watch: {
    // preventDefault error, in gaurdEvent, defaultPrevented, vue-router.esm.js lines 1134 1242 and 2283 is where this maybe can be disabled.  Basically either it needs to be disabled without affection the router functionality, or else possibly $route could be fired, or to.name could be set, or this.toggleLayers(false) could be commited.. anything to trigger this.toggleLayers(false) or else commit this.$store.commit('MAP_VISIBILITY', false, or...

    // as of now this is not beign fired when its clicked, nothing on the page is fired when the same link is clicked because its stopped by the prevenetDefault function in vue-router.esm, it doesnt event make it to router.js's beforeEach function

    $route(to, from) {
      // hide the layers panel when changing routes if the route isn't from home
      // console.log("fired", "from: ", from.name, "to: ", to.name)
      if (to.name !== from.name && from.name !== 'home') {
        this.toggleLayers(false)
      }

      // if (to.name) { 
      //   console.log("from: ", from.name, "to: ", to.name)
      //   this.toggleLayers(false) }


      // if there is a variable in the url, and it's the first page load (from.name === null) show the layer config tools
      if (from.name === null && this.urlVariable) { this.toggleLayers(true) }

      if (to.name === 'home') {
        this.toggleLayers(false)
        this.toggleMap(true)
        window.history.pushState({}, document.title, window.location.pathname) // clear url parameters MANUALLY
      }

      // all direct inbound links to about-data will show climate tools
      if (to.name === 'about-data' && from.name === null) {
        this.toggleLayers(false)
      }

      this.$store.commit('MAP_VISIBILITY', true)
    },
    urlVariable(variable) {
      if (variable) this.$store.commit('CLIMATE_VARIABLE', variable)
    },
    urlRange(range) {
      if (range) this.$store.commit('CLIMATE_RANGE', titleCase(range))
    },
    // --update
    urlScenario(scenario) {
      // if (scenario) this.$store.commit('CLIMATE_SCENARIO', scenario  === 'rcp85' ? 'Higher Global Emissions' : 'Lower Global Emissions')
      if (scenario) this.$store.commit('CLIMATE_SCENARIO', scenario === 'ssp585' ? 'Higher Global Emissions' : 'Lower Global Emissions')
    },
    urlPeriod(period) {
      if (period) this.$store.commit('CLIMATE_PERIOD', period)
    },
    climateVariable(variable) {
      const query = Object.assign({}, this.$route.query)
      query.variable = variable

      this.$router.replace({ query }).catch(() => { })
    },
    climateRange(range) {
      const query = Object.assign({}, this.$route.query)
      query.range = range.toLowerCase()
      this.$router.replace({ query }).catch(() => { })
    },
    climatePeriod(period) {
      const query = Object.assign({}, this.$route.query)
      query.period = period
      this.$router.replace({ query }).catch(() => { })
    },
    // --update
    climateScenario(scenario) {
      const query = Object.assign({}, this.$route.query)

      // query.scenario = scenario === 'Higher Global Emissions' ? 'rcp85' : 'rcp45'
      query.scenario = scenario === 'Higher Global Emissions' ? 'ssp585' : 'ssp245'
      this.$router.replace({ query }).catch(() => { })
    }
  },
  mounted() {

    ////////// *****   POPUP FUNCTION   ******* ///////////////

    setTimeout(() => {
      this.$notify({
        group: 'notify',
        title: 'Feedback',
        text: 'Would you mind giving some feedback to help improve the site?',
        duration: 25000,
        type: 'feedback',
      })
    }, 20000)

    this.$root.$on('map/toggle', () => { this.toggleMap() })
    this.$root.$on('map/show', () => { this.toggleMap(true) })
    this.$root.$on('map/hide', () => { this.toggleMap(false) })

    this.$root.$on('layers/toggle', () => { this.toggleLayers() })
    this.$root.$on('layers/show', () => { this.toggleLayers(true) })
    this.$root.$on('layers/hide', () => { this.toggleLayers(false) })
  },
  methods: {

    toggleMap(state) {
      if (state !== undefined) {
        this.$store.commit('MAP_VISIBILITY', state)
      } else {
        this.$store.commit('MAP_TOGGLE')
        // this.showMap ? this.showMap = false : this.showMap = true
      }
    },

    toggleMobileMap(state) {
      if (state !== undefined) {
        this.showMobileMap = !this.showMobileMap
      } else {
        this.showMobileMap = state
      }
    },
    toggleLayers(state) {
      if (state !== undefined) {
        this.$store.commit('LAYERS_VISIBILITY', state)
      } else {
        this.$store.commit('LAYERS_TOGGLE')
      }
    },
    handleShowGrid(event) {
      if (event.target.checked === true) {
        this.$store.commit('GRID_LAYER_VISIBILITY', true)
      } else {
        this.$store.commit('GRID_LAYER_VISIBILITY', false)
      }
    },
    handleDownload() {
      if (this.$store.state.showDownloadGridLayer === true) {
        this.$store.commit('DOWNLOAD_LAYER_VISIBILITY', false)
      } else {
        this.$store.commit('DOWNLOAD_LAYER_VISIBILITY', true)
      }
    },
    activateClimateSection(section) {
      if (this.climateSection !== section) {
        this.climateSection = section
      } else {
        this.climateSection = null
        this.$store.commit('CLIMATE_VARIABLE', null)
      }
    }
  }
}
</script>

<style lang="scss">
@responsive {
  .flex-fixed {
    flex: 0 0 500px;
  }
}

.mobile-map-container {
  transition: transform ease-in-out 0.13333s;

  &>* {
    -webkit-transform: translateZ(0);
  }

  &.hide {
    transform: translateY(-100vh);
  }
}

.mobile-map-toggle-container {
  position: absolute;
  height: 35px;
  bottom: 0;
  left: 50%;
  margin-left: -4.125rem;
  width: 8.25rem;
}

.mobile-map {
  .map-frame {
    width: 100vw;
    height: 100vh;
  }
}

.tr-controls {
  width: 2.25rem;
  height: 2.25rem;
  right: .55rem;
  top: 5.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);

  .material-icons {
    font-size: 1.5rem;
  }
}

.climate-layer-section-picker {
  @apply flex text-xs justify-between items-center text-center;

  &>div {
    flex-grow: 1;
    cursor: pointer;
    flex-basis: calc(20% - 0.25rem);
    width: calc(20% - 0.25rem);
    margin: 0.25 rem;
    height: 6rem;

    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 0.05 rem;

    img {
      display: block;
      width: 45px;
    }
  }

  .hc {
    @apply bg-red;
  }

  .gs {
    @apply bg-green-alt;
  }

  .ep {
    @apply bg-blue;
  }

  .ap {
    @apply bg-teal;
  }

  .at {
    @apply bg-orange;
  }
}

.climate-layer-section {
  @apply flex flex-wrap justify-between;
}

.climate-layer-section::after {
  content: "";
  flex-basis: calc(33.333% - 0.5rem);
}

.map-frame {
  height: calc(100vh - 30px);
  min-height: 0;
  min-width: 0;
}

.app-container {
  min-height: calc(100vh - 30px);
}

#footer {
  flex-basis: 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.time-container {
  max-height: 210px;
  overflow-y: auto !important;
  pointer-events: auto;
}


.my-style {
  // Style of the notification itself - group notify

  padding: 10px;
  margin: 10px;
  font-size: 14px;
  background: #44A4FC !important;
  color: white;
  border-left: 5px solid #187FE7;


  .notification-title {
    // Style for title line
  }

  .notification-content {
    // Style for content
  }
}
</style>
